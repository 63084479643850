import { WebGLRenderTarget, ShaderMaterial } from 'three';
import { Shaders } from '../Shader.js'

export default class postEffectPass {
    constructor(scene, camera, postCamera, renderer, quad, vig = {}) {
        const shaders = new Shaders();
        const uniforms = {
            tDiffuse: { value: null },
            gNoise: { value: 0.0 },
            time: { value: 0.0 },
            vigOpacity: { value: vig.opacity || 0.125 },
            vigExtent: { value: vig.extent || 0.8 },
            vigIntensity: { value: vig.intensity || 20.0 }
        };

        this.vig = true;
        this.grain = true;
        this.posttarget =
            new WebGLRenderTarget(window.innerWidth, window.innerHeight);
        this.finalMat = new ShaderMaterial({
            uniforms: uniforms,
            fragmentShader: shaders.postShader(),
            vertexShader: shaders.vertexShader()
        });
        this.postScene = scene;
        this.renderer = renderer;
        this.postCamera = postCamera;
        this.camera = camera;
        this.quad = quad;
        this.timer = 0.0;
    }
    dispose() {
        this.posttarget.dispose();
    }
    resize(w, h) {
        this.posttarget.setSize(w, h);
    }
    update(renderTexture, noiseGrain) {
        this.renderer.setRenderTarget(this.posttarget);
        this.quad.material = this.finalMat;
        this.quad.material.uniforms.time.value = this.timer;
        this.quad.material.uniforms.gNoise.value = noiseGrain ? 0.0 : 1.0;
        this.timer += 0.1;
        this.quad.material.uniforms.tDiffuse.value = renderTexture;
        this.renderer.render(this.postScene, this.postCamera);
        this.renderer.setRenderTarget(null);
    }
}