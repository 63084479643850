import React from 'react'
import { BrowserRouter, Redirect, Route } from 'react-router-dom'

import Viewer from './components/Viewer'

import './App.css'

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Route exact path='/'>
                <Redirect to='/CUBE' />
            </Route>
            <Route exact path='/:filename'>
                <Viewer />
            </Route>
        </BrowserRouter>
    )
}

export default App
