// import { Loader } from '@react-three/fiber';
import { useAsset } from 'use-asset'
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"

// type LoaderResult<T> = T extends any[] ? Loader<T[number]> : Loader<T>;
type ObjectMap = {
    nodes: { [name: string]: THREE.Object3D }
    materials: { [name: string]: THREE.Material }
}

export default function AssetLoader(
    Proto: any,
    input: any,
    extensions?: (loader: any) => any,
    onProgress?: (loader: any) => any
): GLTF & ObjectMap {
    // Use suspense to load async assets
    const keys = Array.isArray(input) ? input : [input]
    const results: any = useAsset(loadingFn(extensions, onProgress), Proto, ...keys) // Return the object/s

    return Array.isArray(input) ? results : results[0]
}

function buildGraph(object: any) {
    const data: any = {
        nodes: {},
        materials: {}
    }

    if (object) {
        object.traverse((obj: any) => {
            if (obj.name)
                data.nodes[obj.name] = obj

            if (obj.material && !data.materials[obj.material.name])
                data.materials[obj.material.name] = obj.material
        })
    }

    return data
}

function loadingFn(extensions: any, onProgress: any) {
    return function (Proto: any, ...input: any) {
        // Construct new loader and run extensions
        const loader = new Proto()
        if (extensions) extensions(loader) // Go through the urls and load them

        return Promise.all(input.map((input: any) => new Promise((res, reject) => loader.load(input, (data: any) => {
            if (data.scene) Object.assign(data, buildGraph(data.scene))
            res(data)
        }, onProgress, (error: any) => {
            res(null)
            // reject(`Could not load ${input}: ${error.message}`)
        }))))
    }
}
