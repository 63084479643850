import React, { Suspense } from 'react'
import { Color, MOUSE } from 'three'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, PerspectiveCamera, Stats } from '@react-three/drei'
import { ResizeObserver } from '@juggle/resize-observer'

import { useLoggerStore } from './ui/Logger'

import { useViewerStore } from './Viewer'
import Loading from './Loading'
import Voxo, { useVoxoStore } from './Voxo'
import LightRig from './LightRig'
import { Environment } from './Environment'
import { Stars } from './Stars'
import EffectManager from './EffectManager'
import ErrorBoundary from './ErrorBoundary'

import { ASSET, mode } from '../constants'

const EnvironmentLight: React.FC = () => {
    const [voxoName, cube] = useViewerStore(state => [state.urlReq.voxoName, state.urlReq.extra_mint_cycle])
    const cubeActive = useVoxoStore(state => state.cubeActive)
    const showWhirlwind = cubeActive || voxoName === ASSET.CUBE

    return <>
        {/^true$/i.test(cube) && <Environment preset='whirlwind' active={showWhirlwind} />}
        <Environment preset='forest' active={!showWhirlwind} />
    </>
}

const DomeLight = () => {
    const [dome_color, dome_intensity] = useViewerStore(state => [
        state.urlReq.dome_color,
        state.urlReq.dome_intensity
    ])

    return <hemisphereLight
        color={new Color(dome_color)}
        intensity={dome_intensity}
    />
}

// const UserLights = () => {
//     const _localState = JSON.parse(localStorage.getItem('vvUserLights') || '{}')
//     const userLights = useControls({
//         light0: _localState.light0 || false,
//         location0: { value: _localState.location0 || [0, 0, 0], render: get => get('light0') },
//         color0: { value: _localState.color0 || 'white', render: get => get('light0') },
//         intensity0: { value: _localState.intensity0 || 0, render: get => get('light0') },

//         light1: _localState.light1 || false,
//         location1: { value: _localState.location1 || [0, 0, 0], render: get => get('light1') },
//         color1: { value: _localState.color1 || 'white', render: get => get('light1') },
//         intensity1: { value: _localState.intensity1 || 0, render: get => get('light1') },

//         light2: _localState.light2 || false,
//         location2: { value: _localState.location2 || [0, 0, 0], render: get => get('light2') },
//         color2: { value: _localState.color2 || 'white', render: get => get('light2') },
//         intensity2: { value: _localState.intensity2 || 0, render: get => get('light2') }
//     })

//     useEffect(() => {
//         localStorage.setItem('vvUserLights', JSON.stringify(userLights))
//     }, [userLights])

//     return <>
//         <pointLight
//             position={userLights.location0}
//             color={userLights.color0}
//             intensity={userLights.intensity0}
//         />
//         <pointLight
//             position={userLights.location1}
//             color={userLights.color1}
//             intensity={userLights.intensity1}
//         />
//         <pointLight
//             position={userLights.location2}
//             color={userLights.color2}
//             intensity={userLights.intensity2}
//         />
//     </>
// }

const ThreeCanvas: React.FC = () => {
    const turntable = useVoxoStore(state => state.turntable)
    const cameraData = useViewerStore(state => state.cameraData)
    const setCameraControl = useViewerStore(state => state.setCameraControl)
    const setCameraDataLog = useLoggerStore(state => state.setCameraDataLog)
    const vig = useViewerStore(state => state.urlReq.vig)
    let camera: THREE.PerspectiveCamera
    let control: any

    console.log('🟢 ThreeJS loaded on', process.env.REACT_APP_mode || 'localhost')

    return (
        <Canvas shadows mode="concurrent" dpr={[1, 2]} resize={{ polyfill: ResizeObserver }}>
            <PerspectiveCamera
                fov={cameraData.fov}
                makeDefault={true}
                position={cameraData.loc}
                ref={(_camera: any) => {
                    if (_camera && (!camera || camera.uuid !== _camera.uuid)) { camera = _camera }
                }}
            />
            <OrbitControls
                autoRotate={turntable}
                autoRotateSpeed={0.4}
                target={cameraData.lookat}
                minDistance={3.5}
                maxDistance={25}
                maxPolarAngle={Math.PI / 2}
                mouseButtons={{
                    LEFT: MOUSE.LEFT,
                    RIGHT: MOUSE.RIGHT,
                    MIDDLE: MOUSE.RIGHT
                }}
                onChange={_ => 
                    setCameraDataLog({
                        camloc: camera.position.toArray(),
                        camlookat: control !== null && control.target !== null ? control.target.toArray() : [0, 0, 0],
                        fov: cameraData.fov
                    })
                }
                onUpdate={self => {
                    control = self
                    setCameraControl(self)

                    if (self.autoRotate !== turntable) {
                        self.object.position.set(...cameraData.loc)
                        self.target.set(...cameraData.lookat)
                    }
                }}
            />
            <DomeLight />
            {/* {process.env.REACT_APP_MODE !== 'production' && <UserLights />} */}
            <Suspense fallback={<Loading />}>
                {!mode.production && <Stats />}
                <EnvironmentLight />
                <Stars radius={100} count={500} factor={6} saturation={0.5} fade />
                <ErrorBoundary><Voxo /></ErrorBoundary>
                <ErrorBoundary><LightRig /></ErrorBoundary>
                <EffectManager vig={vig} />
            </Suspense>
        </Canvas>
    )
}

export default ThreeCanvas