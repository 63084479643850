import React, { forwardRef, useMemo, useLayoutEffect } from 'react';
import { Effect, BlendFunction } from 'postprocessing';
import { Archetype, Rarity } from './components/@types';

import {
    RARITY, EXT, voxoTree, possibleUrlParam, urlParamAlias, bucket,
    GLB_TYPE, ASSET, bustAssets, defaultCameraLoc, defaultCameraLookat, defaultFov
} from './constants';

export type VEC3 = [number, number, number]

type DefaultProps = Partial<{ blendFunction: BlendFunction; opacity: number }>
type GearOption = { [gearOption: string]: boolean }
type UrlConfig = {
    bg: string
    // gear: GearState
    portal: boolean
    light: string
    company: string[]
    party: string[]
    interval: string
    turntable: string
    vig: VEC3
    camloc: VEC3
    camlookat: VEC3
    fov: number
    omegakey: string
    gilded: boolean
    
    scene: string
    extra_scene: string
    extra_scene_anomalous: string
    extra_420: string
    extra_mint_cycle: string
    extra_pet: string
    extra_pet_collab: string
    extra_pet_robit: string
    extra_bonsai: string

    dome_color: string
    dome_intensity: number
    token_id: string
}
type VoxoInfo = {
    voxoName: string
    archetype: string
    faction: string
    rarity: string
    base: string
    isHero: boolean
    type: GLB_TYPE.PRESALE | GLB_TYPE.HERO | GLB_TYPE.SPECIAL | GLB_TYPE.DIAMOND
}

export type GearState = { [gearSlot: string]: GearOption }

export type UrlParseResult = {
    urlReq: UrlConfig & VoxoInfo,
    extras: { gear: GearState }
};

export function swapAxis(vec3: number[], offset: number = 0) {
    return [vec3[0], vec3[2] + offset, -vec3[1]];
}

export function getPath(type: string, subcategory: string | null, filename: string, ext?: EXT) {
    // const extKey = type.toUpperCase() as keyof typeof EXT;
    subcategory = subcategory ? `/${subcategory}` : '';
    ext = (!ext && EXT[type.toUpperCase() as keyof typeof EXT]) || ext;
    filename = filename ? `/${filename}${ext ? '.' + ext : ''}` : '';
    return `${bucket}/${type}${subcategory}${filename}`;
}

export function parseRequest(voxoName: string, cfg: URLSearchParams = new URLSearchParams('')): UrlParseResult {
    const voxo = parseVoxoName(voxoName);
    const cfgFallback = (cfg: null | undefined | string, prefix?: string) => {
        if (cfg)
            return `${prefix || ''}${cfg.trim().toUpperCase()}`;
        else return '';
    };
    const cfgToBoolean = (cfg: string | null) => (cfg && /true/i.test(cfg)) ? true : false;
    const colorFallback = (value: string | null) => {
        if (value) {
            const test1 = /^([0-9A-F]{6}|[0-9A-F]{3})$/i.test(value);
            const test2 = /^0x([0-9A-F]{6}|[0-9A-F]{3})$/i.test(value);

            if (test1)
                return `#${value}`;
            else if (test2)
                return `#${value.substr(2)}`;
        }
        return '';
    };
    const vec3Fallback = (value: string | null, fallback?: VEC3): VEC3 => {
        if (!value) return fallback || [0, 0, 0];
        const numbers = value.split(',').map(v => cfgFallback(v)).filter(v => v.length).map(v => Number(v))
        if (numbers.length >= 3) {
            const [x, y, z] = numbers
            return [x, y, z]
        }
        else {
            return fallback || [0, 0, 0];
        }
    }
    const config: UrlConfig = {
        bg: colorFallback(cfg.get('bg')) || '#0E1C34',
        portal: cfgToBoolean(cfg.get('portal')),
        light: cfgFallback(cfg.get('light')),
        company: (cfg.get('company') || '').split(',').map(v => cfgFallback(v)).filter(v => v.length),
        party: (cfg.get('party') || '').split(',').map(v => cfgFallback(v)).filter(v => v.length),
        interval: cfgFallback(cfg.get('interval')),
        turntable: cfgFallback(cfg.get('turntable')),
        vig: vec3Fallback(cfg.get('vig')),
        camloc: vec3Fallback(cfg.get('camloc'), defaultCameraLoc),
        camlookat: vec3Fallback(cfg.get('camlookat'), defaultCameraLookat),
        fov: Math.sqrt(Math.pow(Number(cfgFallback(cfg.get('fov'))) || defaultFov, 2)),
        omegakey: cfgFallback(cfg.get('omegakey')),
        gilded: cfgToBoolean(cfg.get('gilded')),

        extra_420: cfgFallback(cfg.get('extra_420')).replace(/^joint$/i, ASSET.SPLIFF),
        extra_bonsai: cfgFallback(cfg.get('extra_bonsai')),
        extra_mint_cycle: cfgFallback(cfg.get('extra_mint_cycle')),
        extra_pet: cfgFallback(cfg.get('extra_pet')),
        extra_pet_collab: cfgFallback(cfg.get('extra_pet_collab')),
        extra_pet_robit: cfgFallback(cfg.get('extra_pet_robit')),
        extra_scene: cfgFallback(cfg.get('extra_scene')),
        extra_scene_anomalous: cfgFallback(cfg.get('extra_scene_anomalous')),
        scene: cfgFallback(cfg.get('scene')),
        token_id: cfgFallback(cfg.get('token_id')),

        dome_color: colorFallback(cfg.get('dome_color')) || 'white',
        dome_intensity: Number(cfgFallback(cfg.get('dome_intensity')))
    };

    if (voxoName === 'ASC-01') {
        if (config.extra_420 in urlParamAlias)
            config.extra_420 = urlParamAlias[config.extra_420];

        //  use blue tipped spliff instead of red
        if (config.extra_420 === 'SPLIFF')
            config.extra_420 = 'SPLIFF-ASC-01'
    }

    if (config.vig.length !== 3 || config.vig.some(val => typeof val !== 'number')) {
        config.vig = [0.125, 0.8, 20];
    }

    false && getTestUrls(voxo.archetypes);

    return {
        urlReq: {
            voxoName,
            archetype: voxo.archetype,
            faction: voxo.faction,
            rarity: voxo.faction,
            base: voxo.base,
            isHero: voxo.isHero,
            type: voxo.type,
            ...config
        },
        extras: {
            gear: cfgFallback(cfg.get('gear')).split(';').reduce((acc, grp) => {
                const slotItems = grp.split(',').map(gear => gear.trim());
                const stateKey = slotItems.length ? slotItems.shift() : null;

                stateKey && slotItems.forEach(item => {
                    if (stateKey in acc)
                        acc[stateKey][item] = false;
                    else
                        acc[stateKey] = { [item]: false };
                });
                return acc;
            }, {} as GearState)
        }
    };
}

export function getArchetypes() {
    return Object.values(voxoTree).reduce((acc: Archetype, species) => {
        typeof species === 'object' && Object.entries(species).forEach(
            ([a_name, rarities]) => (acc[a_name] = rarities)
        );
        return acc;
    }, {});
}

export function parseVoxoName(filename: string) {
    const [archetype, faction, rarity] = filename.replace(/^HC-/i, '').split('_');
    const archetypes = getArchetypes();
    const archetypeCode = archetype.split('-')[0];
    const rarityCode = rarity && rarity.split(/\d+/)[0]
    const rarityMatch = (r: Rarity) => Object.entries(r).filter(([_, n]) => n).some(([_r, n]) => {
        for (let i = 1; i <= n; i++) {
            const match = `${_r}${pad(i, 4)}` === rarity;
            if (match) return true;
        }
        return false;
        
    });
    const existInTree = () => Object.entries(archetypes[archetype]).some(([f, r]) => {
        if (f === faction)
            return rarityMatch(r);
        else if (!faction) {
            _faction = Object.keys(archetypes[archetype])[0];
            return true;
        }
        return false;
    });
    let isHero = false,
        type = GLB_TYPE.PRESALE,
        _faction = '',
        base = faction;

    if (archetype in archetypes) {
        if (typeof archetypes[archetype] === 'number' || existInTree()) {
            isHero = true;
            type = GLB_TYPE.HERO;
        }

        if (rarityCode === RARITY.SR)
            base = `${archetypeCode}-${rarityCode}-${faction}`;
        else if (rarityCode === RARITY.UR)
            base = `${archetypeCode}-${RARITY.UR}`;
    }
    else if ([...bustAssets, ASSET.ROBOT_BUST, ASSET.MECHA_CHAOTIC, ASSET.ASC05_SPOOKY, ASSET.GOODBOI, ASSET.GOODBOI_FAB].includes(filename as ASSET)) {
        type = GLB_TYPE.SPECIAL;
    }

    return {
        isHero,
        type,
        base: base || _faction,
        archetype,
        rarity,
        archetypes,
        archetypeCode,
        faction: faction || _faction || '',
        rarityCode: rarityCode || RARITY.UQ
    }
}

export const wrapEffect = <T extends new (...args: any[]) => Effect>(
    effectImpl: T,
    defaultBlendMode: BlendFunction = BlendFunction.NORMAL
) => {
    return forwardRef<T, ConstructorParameters<typeof effectImpl>[0] & DefaultProps>(function Wrap(
        { blendFunction, opacity, ...props }: React.PropsWithChildren<DefaultProps & ConstructorParameters<T>[0]>,
        ref
    ) {
        const effect: Effect = useMemo(() => new effectImpl(props), [props])

        useLayoutEffect(() => {
            effect.blendMode.blendFunction = blendFunction || defaultBlendMode
            if (opacity !== undefined)
                effect.blendMode.opacity.value = opacity
        }, [
            blendFunction,
            effect.blendMode,
            opacity
        ]);

        return <primitive ref={ref} object={effect} dispose={null} />
    })
};

export function capLeadingLetter(text: string) {
    return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
}

export function toTitle(text: string) {
    return text.split(/\s+|_|-/).map(word => capLeadingLetter(word)).join(' ');
}

function pad(number: number | string, length: number) {
    let str = '' + number;
    while (str.length < length) { str = '0' + str }
    return str;
}

function getRandomItem(arr: Array<string>) {
    // get random index value
    const randomIndex = Math.floor(Math.random() * arr.length);
    // get random item
    const item = arr[randomIndex];

    return item;
}

function getUrlParams(_idx: number) {
    let params: Array<string> = [];

    Object.entries(possibleUrlParam).forEach(([p_name, p_values]) => {
        if (p_values.length && Math.random() > 0.25)
            params.push(`${p_name}=${getRandomItem([...p_values])}`)
    });

    return params.join('&');
}

function getTestUrls(archetypes: Archetype) {
    testUrl = testUrl || Object.fromEntries(Object.entries(archetypes).map(([a_name, faction], idx) => {
        const factionKeys = Object.keys(faction);
        const randFactionKeys = Math.floor(Math.random() * factionKeys.length);
        const tiers = Object.values(faction)[randFactionKeys];
        // let url = `https://voxo-3js-staging.herokuapp.com/${a_name}`;
        let url = `https://voxo-3js-mainnet.herokuapp.com/${a_name}`;

        if (tiers && !tiers.UQ) {
            !tiers.UR && delete tiers.UR;

            const tierKeys = Object.keys(tiers).filter(key => tiers[key]);
            const randTierKey = Math.floor(Math.random() * tierKeys.length);
            const n = pad(Object.values(tiers)[randTierKey], 4);

            url += `_${factionKeys[randFactionKeys]}_${tierKeys[randTierKey]}${n}`;
        }

        return [idx + 1, `${url}?${getUrlParams(idx)}`];
    }));

    console.log(JSON.stringify(testUrl));
}

let testUrl: object;

// getTestUrls();
