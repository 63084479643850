import React from 'react'
import styled from 'styled-components'
import QRCode from 'react-qr-code'

import view_in_AR_icon from '../../assets/img/view_in_AR_icon.svg'

export type ViewInARProps = {
    voxoId: string | number
}

export const QRCodeSectionDetailsPage = styled.div`
    position: absolute;
    background: ghostwhite;
    box-shadow: 0px 0.418303px 26.1439px rgba(171, 155, 233, 0.5);
    border-radius: 5px;
    flex-direction: column;
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 20px 30px;
    z-index: 10;
    align-items: center;
    text-shadow: 0px 0.698917px 43.6823px #ab9be9;  
    top: 110px;
    right: 60px;
    height: max-content;
    width: max-content;
    display: none;
`
const ViewInARContainer = styled.div`
    &:hover {
    ${QRCodeSectionDetailsPage} {
        display: flex;
    }
}`
const ViewInARIcon = styled.img`
    opacity:0.3;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 1;
    }
`
export const QRHeader = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    color: #2a4060;
    opacity: 0.4;
`
export const QRCodeWrapper = styled.div`
    width: 65px;
    height: 65px;
    margin-top: 5px;
    > svg {
        margin: 0 !important;
    }
`
export const QRSubHeader = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2a4060;
    margin-top: 10px;
`
const ViewInAR: React.FC<ViewInARProps> = props => {
    return <ViewInARContainer>
        <a href={`https://voxodeus.io/ar/${props.voxoId}`} target="_blank" rel="noreferrer">
            <ViewInARIcon src={view_in_AR_icon} />
        </a>
        <QRCodeSectionDetailsPage>
            <QRHeader>VIEW ON MOBILE</QRHeader>
            <QRSubHeader>Scan QR code</QRSubHeader>
            <QRCodeWrapper>
                <QRCode size={65} value={`https://voxodeus.io/ar/${props.voxoId}`} fgColor="#2A4060" />
            </QRCodeWrapper>
        </QRCodeSectionDetailsPage>
    </ViewInARContainer>
}

export default ViewInAR