import create from 'zustand'
import styled from 'styled-components'
import React, { useState } from 'react'

import Toggle from './Toggle'
import { useVoxoStore } from '../Voxo'
import { useViewerStore } from '../Viewer'
import { cameraLocForPet, cameraLookatForPet } from '../../constants'

import config_icon from '../../assets/img/config.png'
import checkbox_on from '../../assets/img/checkbox_on.svg'
import checkbox_off from '../../assets/img/checkbox_off.svg'
import { toTitle } from '../../utils'

type MenuStore = {
    showConfig: boolean
    showEnvToggle: boolean
    showExtraEnvToggle: boolean
    showAnomalousEnvToggle: boolean
    showAddonToggle: boolean
    showPetToggle: boolean
    showPetRobitToggle: boolean
    showPetCollabToggle: boolean
    showBonsaiToggle: boolean
    showOmegaKeyToggle: boolean
    showGildedToggle: boolean
    toggleConfig: () => void
    addEnvToggle: () => void
    addExtraEnvToggle: () => void
    addAnomalousEnvToggle: () => void
    addAddonToggle: () => void
    addPetToggle: () => void
    addPetRobitToggle: () => void
    addPetCollabToggle: () => void
    addBonsaiToggle: () => void
    addOmegaKeyToggle: () => void
    addGildedToggle: () => void
}
type ToggleValidity = { isValid?: boolean }

type EnvToggleProps = ToggleValidity
type ExtraEnvToggleProps = ToggleValidity
type AddonToggleProps = ToggleValidity
type PetToggleProps = ToggleValidity
type BonsaiToggleProps = ToggleValidity

enum LABEL {
    SCENE = 'Scene:',
    SCENE_ANOMALOUS = 'Scene Anomalous:',
    PET = 'Pet:',
    PET_ROBIT = 'Pet Robit:',
    PET_COLLAB = 'Pet Collab:',
    ADDON = '420:',
    BONSAI = 'Bonsai:',
    OMEGA = 'Omega Key:',
    GILDED = 'Color:',
    CUBE = 'Mint Cycle:',
}

const EnvToggle: React.FC<EnvToggleProps> = () => {
    const [env, cameraControl] = useViewerStore(state => [state.urlReq.scene, state.cameraControl])
    const [bonsaiActive, envActive, cubeActive, toggleEnv, toggleBonsai] = useVoxoStore(state => [
        state.bonsaiActive,
        state.envActive,
        state.cubeActive,
        state.toggleEnv,
        state.toggleBonsai
    ])

    return <Toggle
        testId='EnvToggle'
        asset={env}
        label={LABEL.SCENE}
        description={env}
        init={envActive}
        on={checkbox_on}
        off={checkbox_off}
        onToggle={() => {
            if (cubeActive) return
            if (bonsaiActive) toggleBonsai()
            toggleEnv()
            cameraControl.maxPolarAngle = envActive ? Math.PI / 2 : Math.PI
        }}
    />
}
const ExtraEnvToggle: React.FC<ExtraEnvToggleProps> = () => {
    const [extraEnv, cameraControl] = useViewerStore(state => [state.urlReq.extra_scene, state.cameraControl])
    const [bonsaiActive, extraEnvActive, cubeActive, toggleBonsai, toggleExtraEnv] = useVoxoStore(state => [
        state.bonsaiActive,
        state.extraEnvActive,
        state.cubeActive,
        state.toggleBonsai,
        state.toggleExtraEnv
    ])

    return <Toggle
        testId='ExtraEnvToggle'
        asset={extraEnv}
        label={LABEL.SCENE}
        description={extraEnv}
        init={extraEnvActive}
        on={checkbox_on}
        off={checkbox_off}
        onToggle={() => {
            if (cubeActive) return
            if (bonsaiActive) toggleBonsai()
            toggleExtraEnv()
            cameraControl.maxPolarAngle = extraEnvActive ? Math.PI / 2 : Math.PI
        }}
    />
}
const AnomalousEnvToggle: React.FC<ToggleValidity> = () => {
    const [anomalousEnv, cameraControl] = useViewerStore(state => [state.urlReq.extra_scene_anomalous, state.cameraControl])
    const [bonsaiActive, anomalousEnvActive, cubeActive, toggleBonsai, toggleAnomalousEnv] = useVoxoStore(state => [
        state.bonsaiActive,
        state.anomalousEnvActive,
        state.cubeActive,
        state.toggleBonsai,
        state.toggleAnomalousEnv
    ])

    return <Toggle
        testId='AnomEnvToggle'
        asset={anomalousEnv}
        label={LABEL.SCENE_ANOMALOUS}
        description={anomalousEnv}
        init={anomalousEnvActive}
        on={checkbox_on}
        off={checkbox_off}
        onToggle={() => {
            if (cubeActive) return
            if (bonsaiActive) toggleBonsai()
            toggleAnomalousEnv()
            cameraControl.maxPolarAngle = anomalousEnvActive ? Math.PI / 2 : Math.PI
        }}
    />
}
const AddonToggle: React.FC<AddonToggleProps> = () => {
    const addon = useViewerStore(state => state.urlReq.extra_420)
    const [addonActive, cubeActive] = useVoxoStore(state => [state.addonActive, state.cubeActive])
    const toggle = useVoxoStore(state => !cubeActive ? state.toggleAddon : () => { })

    return <Toggle
        testId='AddonToggle'
        asset={addon}
        label={LABEL.ADDON}
        description={addon}
        init={addonActive}
        on={checkbox_on}
        off={checkbox_off}
        onToggle={toggle}
    />
}
const PetToggle: React.FC<PetToggleProps> = () => {
    const [pet, setCamera] = useViewerStore(state => [state.urlReq.extra_pet, state.setCamera])
    const [petActive, cubeActive, togglePet] = useVoxoStore(state => [
        state.petActive,
        state.cubeActive,
        state.togglePet
    ])

    return <Toggle
        testId='PetToggle'
        asset={pet}
        label={LABEL.PET}
        description={pet}
        init={petActive}
        on={checkbox_on}
        off={checkbox_off}
        onToggle={() => {
            if (cubeActive) return
            if (!petActive) setCamera({ loc: cameraLocForPet, lookat: cameraLookatForPet })
            togglePet()
        }}
    />
}
const PetRobitToggle: React.FC<PetToggleProps> = () => {
    const [petRobit, setCamera] = useViewerStore(state => [
        state.urlReq.extra_pet_robit,
        state.setCamera
    ])
    const [petRobitActive, cubeActive, togglePetRobit] = useVoxoStore(state => [
        state.petRobitActive,
        state.cubeActive,
        state.togglePetRobit
    ])

    return <Toggle
        testId='PetRobitToggle'
        asset={petRobit}
        label={LABEL.PET_ROBIT}
        description={petRobit}
        init={petRobitActive}
        on={checkbox_on}
        off={checkbox_off}
        onToggle={() => {
            if (cubeActive) return
            if (!petRobitActive) setCamera({ loc: cameraLocForPet, lookat: cameraLookatForPet })
            togglePetRobit()
        }}
    />
}
const PetCollabToggle: React.FC<PetToggleProps> = () => {
    const [petCollab, setCamera] = useViewerStore(state => [
        state.urlReq.extra_pet_collab,
        state.setCamera
    ])
    const [petCollabActive, cubeActive, togglePetCollab] = useVoxoStore(state => [
        state.petCollabActive,
        state.cubeActive,
        state.togglePetCollab
    ])

    return <Toggle
        testId='PetCollabToggle'
        asset={petCollab}
        label={LABEL.PET_COLLAB}
        description={petCollab}
        init={petCollabActive}
        on={checkbox_on}
        off={checkbox_off}
        onToggle={() => {
            if (cubeActive) return
            if (!petCollabActive) setCamera({ loc: cameraLocForPet, lookat: cameraLookatForPet })
            togglePetCollab()
        }}
    />
}
const BonsaiToggle: React.FC<BonsaiToggleProps> = () => {
    const bonsai = useViewerStore(state => state.urlReq.extra_bonsai)
    const [
        bonsaiActive, envActive, extraEnvActive, anomalousEnvActive, cubeActive,
        toggleEnv, toggleExtraEnv, toggleAnomalousEnv, toggleBonsai
    ] = useVoxoStore(state => [
        state.bonsaiActive,
        state.envActive,
        state.extraEnvActive,
        state.anomalousEnvActive,
        state.cubeActive,
        state.toggleEnv,
        state.toggleExtraEnv,
        state.toggleAnomalousEnv,
        state.toggleBonsai
    ])

    return <Toggle
        testId='BonsaiToggle'
        asset={bonsai}
        label={LABEL.BONSAI}
        description={bonsai}
        init={bonsaiActive}
        on={checkbox_on}
        off={checkbox_off}
        onToggle={() => {
            if (cubeActive) return
            if (envActive) toggleEnv()
            if (extraEnvActive) toggleExtraEnv()
            if (anomalousEnvActive) toggleAnomalousEnv()
            toggleBonsai()
        }}
    />
}
const OmegaKeyToggle: React.FC = () => {
    const omegaKey = useViewerStore(state => state.urlReq.omegakey).toLowerCase()
    const [
        omegaKeyActive, envActive, extraEnvActive, anomalousEnvActive, cubeActive,
        toggleEnv, toggleExtraEnv, toggleAnomalousEnv, toggleOmegaKey
    ] = useVoxoStore(state => [
        state.omegaKeyActive,
        state.envActive,
        state.extraEnvActive,
        state.anomalousEnvActive,
        state.cubeActive,
        state.toggleEnv,
        state.toggleExtraEnv,
        state.toggleAnomalousEnv,
        state.toggleOmegaKey
    ])

    if (!['true', 'false'].includes(omegaKey)) { return <></> }

    const assetName = `OMEGA-STAGE-${omegaKey === 'true' ? '0' : '2'}`

    return <Toggle
        testId='OmegaKeyToggle'
        asset={assetName}
        label={LABEL.OMEGA}
        description={toTitle(assetName.replaceAll('-', ' '))}
        init={omegaKeyActive}
        on={checkbox_on}
        off={checkbox_off}
        onToggle={() => {
            if (cubeActive) return
            if (envActive) toggleEnv()
            if (extraEnvActive) toggleExtraEnv()
            if (anomalousEnvActive) toggleAnomalousEnv()
            toggleOmegaKey()
        }}
    />
}
const GildedToggle: React.FC = () => {
    const [ gildedActive, cubeActive, toggleGilded ] = useVoxoStore(state => [
        state.gildedActive,
        state.cubeActive,
        state.toggleGilded,
    ])

    return <Toggle
        testId='GildedToggle'
        label={LABEL.GILDED}
        description='Gilded'
        init={gildedActive}
        on={checkbox_on}
        off={checkbox_off}
        onToggle={() => {
            if (cubeActive) return
            toggleGilded()
        }}
    />
}
const CubeToggle: React.FC = () => {
    const cube = useViewerStore(state => state.urlReq.extra_mint_cycle)
    const [cubeActive, toggleCube] = useVoxoStore(state => [state.cubeActive, state.toggleCube])

    return /voxocube/i.test(cube) ? <Toggle
        testId='CubeToggle'
        asset={cube}
        label={LABEL.CUBE}
        description='VoxoCube'
        init={cubeActive}
        on={checkbox_on}
        off={checkbox_off}
        onToggle={toggleCube}
    /> : <></>
}
// const GearToggles: React.FC = () => {
//     const gear = useVoxoStore(state => state.gearActive);
//     const gearToggles = Object.keys(gear).map(slot => {
//         console.log(slot);
//         const options = [slot, ...Object.keys(gear[slot])];
//         return options.map(option => <code 
//             key={option}
//             onClick={() => {
//                 // toggleGear(slot, option)
//             }}
//         >{option}</code>);
//     })

//     return <>{gearToggles}</>;
// };
const MenuToggle: React.FC = () => {
    const showConfig = useMenuStore(state => state.showConfig)
    const showEnvToggle = useMenuStore(state => state.showEnvToggle)
    const showExtraEnvToggle = useMenuStore(state => state.showExtraEnvToggle)
    const showAnomalousEnvToggle = useMenuStore(state => state.showAnomalousEnvToggle)
    const showAddonToggle = useMenuStore(state => state.showAddonToggle)
    const showPetToggle = useMenuStore(state => state.showPetToggle)
    const showPetRobitToggle = useMenuStore(state => state.showPetRobitToggle)
    const showPetCollabToggle = useMenuStore(state => state.showPetCollabToggle)
    const showBonsaiToggle = useMenuStore(state => state.showBonsaiToggle)
    const showOmegaKeyToggle = useMenuStore(state => state.showOmegaKeyToggle)
    const showGildedToggle = useMenuStore(state => state.showGildedToggle)

    const toggleConfig = useMenuStore(state => state.toggleConfig)

    const [prevToggle, setPrevToggle] = useState(Date.now())
    const toggle = () => {
        if (Date.now() - prevToggle > 500) {
            toggleConfig()
            setPrevToggle(Date.now())
        }
    }

    return <>
        <Toggle testId='MenuIcon' toggleOnHover init={showConfig} on={config_icon} onToggle={toggle} />
        <MenuWrapper
            onMouseLeave={toggle}
            style={{ opacity: showConfig ? 1 : 0, pointerEvents: showConfig ? 'auto' : 'none' }}
        >
            <MenuHeader>BASE</MenuHeader>
            {showEnvToggle && <EnvToggle />}

            <MenuHeader>EXTRA</MenuHeader>
            {showExtraEnvToggle && <ExtraEnvToggle />}
            {showAnomalousEnvToggle && <AnomalousEnvToggle />}
            {showAddonToggle && <AddonToggle />}
            {showPetToggle && <PetToggle />}
            {showPetRobitToggle && <PetRobitToggle />}
            {showPetCollabToggle && <PetCollabToggle />}
            {showBonsaiToggle && <BonsaiToggle />}
            {showOmegaKeyToggle && <OmegaKeyToggle />}
            {showGildedToggle && <GildedToggle />}
            <CubeToggle />

            {/* http://localhost:3000/sm-01_c_sR0002?gear=ITEM-RH-DAGGER,ITEM-RH-SWORD */}
            {/* <GearToggles /> */}
        </MenuWrapper>
    </>
}
export const useMenuStore = create<MenuStore>(set => ({
    showConfig: false,
    showEnvToggle: false,
    showExtraEnvToggle: false,
    showAnomalousEnvToggle: false,
    showAddonToggle: false,
    showPetToggle: false,
    showPetRobitToggle: false,
    showPetCollabToggle: false,
    showBonsaiToggle: false,
    showOmegaKeyToggle: false,
    showGildedToggle: false,
    toggleConfig: () => set(state => {
        // const config = !state.showConfig || !state.
        return { showConfig: !state.showConfig }
    }),
    addEnvToggle: () => set({ showEnvToggle: true }),
    addExtraEnvToggle: () => set({ showExtraEnvToggle: true }),
    addAnomalousEnvToggle: () => set({ showAnomalousEnvToggle: true }),
    addAddonToggle: () => set({ showAddonToggle: true }),
    addPetToggle: () => set({ showPetToggle: true }),
    addPetRobitToggle: () => set({ showPetRobitToggle: true }),
    addPetCollabToggle: () => set({ showPetCollabToggle: true }),
    addBonsaiToggle: () => set({ showBonsaiToggle: true }),
    addOmegaKeyToggle: () => set({ showOmegaKeyToggle: true }),
    addGildedToggle: () => set({ showGildedToggle: true })
}))
const MenuWrapper = styled.div`
    display: grid;
    grid-row-gap: 10px;
    position: absolute;
    right: 60px;
    padding: 16px 24px;
    border-radius: 5px;
    background-color: ghostwhite;
    transition: opacity 0.2s ease-in-out;
`
const MenuHeader = styled.h4`
    color: silver;
    margin: 0;
    font-family: Arial;
    font-size: 8px;
    letter-spacing: 0.1em;
`

export default MenuToggle