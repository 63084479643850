/**
 * testnet link
 * @see https://testnets.opensea.io/assets/0x48984d97bd06540c9e79845a6a63902155f86049/1
 */

import {VEC3} from './utils'

// const updatePetFiles = `
//  gsutil -m cp APE.glb gs://voxodeus_metadata/src/glb/pet
//  gsutil -m cp BABY-APE.glb gs://voxodeus_metadata/src/glb/pet
//  gsutil -m cp CAT-A.glb gs://voxodeus_metadata/src/glb/pet
//  gsutil -m cp CHIBA.glb gs://voxodeus_metadata/src/glb/pet
//  gsutil -m cp DOG-A.glb gs://voxodeus_metadata/src/glb/pet
//  gsutil -m cp DOG-B.glb gs://voxodeus_metadata/src/glb/pet
//  gsutil -m cp DRAGON.glb gs://voxodeus_metadata/src/glb/pet 
//  gsutil -m cp LAMA.glb gs://voxodeus_metadata/src/glb/pet
//  gsutil -m cp ROBO-CUBE-A.glb gs://voxodeus_metadata/src/glb/pet
//  gsutil -m cp ROBO-CUBE-B.glb gs://voxodeus_metadata/src/glb/pet

//  gsutil -m cp APE.glb gs://voxodeus_metadata/cleanup/glb/extra_pet/APEMIND-APE.glb
//  gsutil -m cp BABY-APE.glb gs://voxodeus_metadata/cleanup/glb/extra_pet/NANO-APE.glb
//  gsutil -m cp CAT-A.glb gs://voxodeus_metadata/cleanup/glb/extra_pet/NEUTRON-CAT.glb
//  gsutil -m cp CHIBA.glb gs://voxodeus_metadata/cleanup/glb/extra_pet/BOOST-SHIBA-DOG.glb
//  gsutil -m cp DOG-A.glb gs://voxodeus_metadata/cleanup/glb/extra_pet/SABRE-DOG.glb
//  gsutil -m cp DOG-B.glb gs://voxodeus_metadata/cleanup/glb/extra_pet/FLASH-DOG.glb
//  gsutil -m cp DRAGON.glb gs://voxodeus_metadata/cleanup/glb/extra_pet/STARSCALES-DRAGON.glb
//  gsutil -m cp LAMA.glb gs://voxodeus_metadata/cleanup/glb/extra_pet/LLASER-LLAMA.glb
//  gsutil -m cp ROBO-CUBE-A.glb gs://voxodeus_metadata/cleanup/glb/extra_pet_collab/VOGUPET-ELDER.glb
//  gsutil -m cp ROBO-CUBE-B.glb gs://voxodeus_metadata/cleanup/glb/extra_pet_collab/VOGUPET.glb

//  gsutil mv gs://voxodeus_metadata/cleanup/glb/extra_bonsai/BONSAI-1-0.glb gs://voxodeus_metadata/cleanup/glb/extra_bonsai/CHERRY-CALM.glb
//  gsutil mv gs://voxodeus_metadata/cleanup/glb/extra_bonsai/BONSAI-1-13.glb gs://voxodeus_metadata/cleanup/glb/extra_bonsai/EMERALD-EASE.glb
//  gsutil mv gs://voxodeus_metadata/cleanup/glb/extra_bonsai/BONSAI-1-14.glb gs://voxodeus_metadata/cleanup/glb/extra_bonsai/SUMMER-BLUSH.glb
//  gsutil mv gs://voxodeus_metadata/cleanup/glb/extra_bonsai/BONSAI-1-15.glb gs://voxodeus_metadata/cleanup/glb/extra_bonsai/THE-PLATEAU.glb
//  gsutil mv gs://voxodeus_metadata/cleanup/glb/extra_bonsai/BONSAI-2-0.glb gs://voxodeus_metadata/cleanup/glb/extra_bonsai/SYSTEMICA-SAPLING.glb
//  gsutil mv gs://voxodeus_metadata/cleanup/glb/extra_bonsai/BONSAI-2-13.glb gs://voxodeus_metadata/cleanup/glb/extra_bonsai/BRIGHTVINE.glb
//  gsutil mv gs://voxodeus_metadata/cleanup/glb/extra_bonsai/BONSAI-2-14.glb gs://voxodeus_metadata/cleanup/glb/extra_bonsai/STUDY-THE-STARS.glb
//  gsutil mv gs://voxodeus_metadata/cleanup/glb/extra_bonsai/BONSAI-2-15.glb gs://voxodeus_metadata/cleanup/glb/extra_bonsai/INNER-LIGHT.glb
//  gsutil mv gs://voxodeus_metadata/cleanup/glb/extra_bonsai/EVENING-REFLECTIONS.glb gs://voxodeus_metadata/cleanup/glb/extra_bonsai/MIRROR-POOLS.glb
//  `

export const mode = {
    staging: process.env.REACT_APP_mode === 'staging',
    production: process.env.REACT_APP_mode === 'production'
};

export type DevProps = {
    testId?: string
}

export const gcs = {
    production: 'https://storage.googleapis.com/voxodeus/cleanup',
    staging: 'https://storage.googleapis.com/voxodeus_metadata/cleanup'
}
export const bucket =  //gcs.production ||
    (mode.production && gcs.production) ||
    (mode.staging && gcs.staging) ||
    '';

export const hdriPreloadPath = 'https://storage.googleapis.com/voxodeus/voxo_assets/img/hdri/'
export const factionSceneNames = {
    A: 'STREET-LEVEL-9B',
    B: 'THE-FRONTIER',
    C: 'HEADQUARTERS',
    D: 'NIGHTSCHOOL',
    SRUR: 'NRG-MEADOW'
}

export const defaultCameraLoc: VEC3 = [5, 5, 8]
export const defaultCameraLookat: VEC3 = [0, 3.5, 0]
export const cameraLocForPet: VEC3 = [6, 5, 18]
export const cameraLookatForPet: VEC3 = [0, 5.5, 0]
export const defaultFov = 50

//  only used to correctly query pre-v3 file names
//  v3 metadata should respond with correct file names therefore
//  this map will be redundant
export const envNameMap = {
    factionEnv: {
        'STREET-LEVEL-9B': 'ANGOS',
        'THE-FRONTIER': 'ASTRAS',
        'HEADQUARTERS': 'CORPO',
        'NIGHTSCHOOL': 'VERITA',
        'NRG-MEADOW': 'SRUR'
    },
    extraEnv: {
        'NEONICA-SPA': 'PINK-NEON',
        'THE-FIRST-GARDEN': 'JUNGLE',
        'THE-FROSTFALL': 'ICE',
        'VAPORCITY': 'NEON-EDO',
        'SMOULDER-STACKS': 'PHOENIX'
    },
    anomalousEnv: {
        'PLANET-PHAELOS': 'ANCIENT-RUIN-ALT'
    }
}
export const specialNaming = [{
    test: /^apemind/i,
    replace: 'ApeMind'
}, {
    test: /^nrg/i,
    replace: 'NRG'
}, {
    test: /^voxocube$/i,
    replace: 'VoxoCube'
}, {
    test: /^roboape$/i,
    replace: 'RoboApe'
}, {
    test: /^robodog$/i,
    replace: 'RoboDog'
}, {
    test: /^robocat$/i,
    replace: 'RoboCat'
}, {
    test: /^vogupet/i,
    replace: 'VoguPet'
}]
//  only used for generating test links
//  no impact on viewer behaviour
export const possibleUrlParam = {
    scene: Object.keys(envNameMap.factionEnv),
    extra_scene: Object.keys(envNameMap.extraEnv),
    extra_scene_anomalous: Object.keys(envNameMap.anomalousEnv),
    extra_420: ['BONG', 'JOINT', 'SPLIFF', 'BLUNT'],
    extra_bonsai: [
        'AUTUMN-BREEZE',
        'BRIGHTVINE',
        'CHERRY-CALM',
        'CARDINAL-GLIMMERFALL',
        'CARDINAL-STREAM',
        'CARMINE-DRIFT',
        'DAWN-BLOOM',
        'DUSK-BLOSSOM',
        'EMERALD-EASE',
        'GLOWROOT',
        'GLOWSAP',
        'INNER-LIGHT',
        'JUNIPER-HILLS',
        'LARCH-ASCENDING',
        'MIRROR-POOLS',
        'NIGHT-ORCHARD',
        'OUTER-OAK',
        'PLUM-SUNSET',
        'SERENE-SEEDING',
        'SEVEN-STARS-FALL',
        'SHADE-VEIL',
        'STARFALL-FRUIT',
        'STUDY-THE-STARS',
        'SUMMER-BLUSH',
        'SUNDOWN-NEBULA',
        'SYSTEMICA-GRAND',
        'SYSTEMICA-SAPLING',
        'THE-PLATEAU',
        'THE-VERMILLION',
        'TIDAL-GROWTH',
        'TWILIGHT-PERCH',
        'VERDANT-VALE'
    ],
    extra_pet: [
        'APEMIND-APE',
        'BIRD-B',
        'BLASTWING-BIRD',
        'BOOST-SHIBA-DOG',
        'FLASH-DOG',
        'LLASER-LLAMA',
        'NANO-APE',
        'NEUTRON-CAT',
        'PHOENIX-ELDER',
        'PHOENIX',
        'SABRE-DOG',
        'SLITHE-SNAKE',
        'STARSCALES-DRAGON',
    ],
    extra_pet_collab: ['VOGUPET', 'VOGUPET-ELDER'],
    extra_pet_robit: ['ROBOAPE', 'ROBODOG', 'ROBOCAT'],
    cube: ['False', 'True'],
}

export enum RARITY {
    R = 'R',
    SR = 'SR',
    UR = 'UR',
    UQ = 'UQ'
}

/**
 * Represents the directory structure of root/glb
 * @example
 * console.log(`${bucket}/${ASSET_TYPE.GLB}/${GLB_TYPE.BONSAI}`)
 * // 'https://storage.googleapis.com/voxodeus/voxo_assets/glb/extra_bonsai'
 */
export enum GLB_TYPE {
    BASE = 'base',
    ENV = 'scene',
    EXTRA_ENV = 'extra_scene',
    ANOM_ENV = 'extra_scene_anomalous',
    GEAR = 'gear',
    ADDON = 'extra_420',
    BONSAI = 'extra_bonsai',
    PET = 'extra_pet',
    PET_COLLAB = 'extra_pet_collab',
    PET_ROBIT = 'extra_pet_robit',
    HERO = 'hero',
    SPECIAL = 'special',
    PRESALE = 'presale',
    LIGHT_RIG = 'light_rigs',
    CARD = 'cards',
    GILDED = 'gilded',
    DIAMOND = 'diamond'
}

export enum ASSET {
    OMEGA = 'OXO-01',
    MEDUSA = 'ASC-07',
    ROBOT_BUST = 'ROBOT-BUST',
    MECHA_CHAOTIC = 'MECHA-CHAOTIC',
    METAKEY_BUST_1 = 'METAKEY-BUST-1',
    METAKEY_BUST_2 = 'METAKEY-BUST-2',
    METAKEY_BUST_3 = 'METAKEY-BUST-3',
    METAKEY_BUST_4 = 'METAKEY-BUST-4',
    METAKEY_BUST_5 = 'METAKEY-BUST-5',
    BUST_0 = 'BUST-0',
    BUST_1 = 'BUST-1',
    BUST_2 = 'BUST-2',
    BUST_3 = 'BUST-3',
    BUST_4 = 'BUST-4',
    BUST_5 = 'BUST-5',
    BUST_6 = 'BUST-6',
    KONG_0 = 'KONG-0',
    KONG_1 = 'KONG-1',
    WHALE_DOLPHIN = 'WHALE-DOLPHIN',
    WHALE_SHARK = 'WHALE-SHARK',
    WHALE_WHALE = 'WHALE-WHALE',
    VOGU_BUST_0 = 'VOGU-BUST-0',
    VOGU_BUST_1 = 'VOGU-BUST-1',
    VOGU_BUST_2 = 'VOGU-BUST-2',
    VOGU_BUST_3 = 'VOGU-BUST-3',
    VOGU_BUST_4 = 'VOGU-BUST-4',
    VOGU_BUST_5 = 'VOGU-BUST-5',
    VOGU_BUST_6 = 'VOGU-BUST-6',
    VOGU_BUST_7 = 'VOGU-BUST-7',
    VOGU_BUST_8 = 'VOGU-BUST-8',
    VOGU_BUST_9 = 'VOGU-BUST-9',
    VOGU_BUST_10 = 'VOGU-BUST-10',
    VOGU_BUST_11 = 'VOGU-BUST-11',
    VOGU_BUST_12 = 'VOGU-BUST-12',
    VOGU_BUST_13 = 'VOGU-BUST-13',
    VOGU_BUST_14 = 'VOGU-BUST-14',
    VOGU_BUST_15 = 'VOGU-BUST-15',
    VOGU_BUST_16 = 'VOGU-BUST-16',
    ASC05_SPOOKY = 'ASC-05-SPOOKY',
    GOODBOI = 'GOODBOI',
    GOODBOI_FAB = 'GOODBOI-FAB',
    CUBE = 'CUBE',
    PENTAFORM = 'PENTAFORM',
    BONG = 'BONG',
    SPLIFF = 'SPLIFF',
    WHIRLWIND = 'cubic_whirlwind_1k',
    WINTER = 'winter_evening_1k',
    BRIDGE = 'adams_place_bridge_1k',
    SNOW = 'snowy_park_01_1k',
    TAKE01 = '1',
    TAKE02 = '2',
    TAKE03 = '3',
    TAKE04 = '4',
    TAKE05 = '5',
    TAKE06 = '6',
    TAKE07 = '7',
    TAKE08 = '8',
    TAKE09 = '9',
    TAKE10 = '10',
    TAKE11 = '11',
    TAKE12 = '12',
    TAKE13 = '13',
    TAKE14 = '14',
    TAKE15 = '15',
    TAKE16 = '16',
    TAKE17 = '17',
    TAKE18 = '18',
    TAKE19 = '19',
    TAKE20 = '20',
    TAKE21 = '21',
    TAKE22 = '22',
    TAKE23 = '23',
    TAKE24 = '24',
    TAKE25 = '25',
    TAKE26 = '26',
    TAKE27 = '27',
    TAKE28 = '28',
    TAKE29 = '29',
    TAKE30 = '30',
    TAKE31 = '31',
    TAKE32 = '32',
    TAKE33 = '33',
    TAKE34 = '34',
    TAKE35 = '35',
    TAKE36 = '36',
    TAKE37 = '37',
    TAKE38 = '38',
    TAKE39 = '39',
    TAKE40 = '40',
    TAKE41 = '41',
    TAKE42 = '42'
}

export const bustAssets: string[] = [
    ASSET.METAKEY_BUST_1, ASSET.METAKEY_BUST_2, ASSET.METAKEY_BUST_3, ASSET.METAKEY_BUST_4, ASSET.METAKEY_BUST_5,
    ASSET.BUST_0, ASSET.BUST_1, ASSET.BUST_2, ASSET.BUST_3, ASSET.BUST_4, ASSET.BUST_5, ASSET.BUST_6, ASSET.KONG_0, ASSET.KONG_1,
    ASSET.WHALE_DOLPHIN, ASSET.WHALE_SHARK, ASSET.WHALE_WHALE,
    ASSET.VOGU_BUST_0, ASSET.VOGU_BUST_1, ASSET.VOGU_BUST_2, ASSET.VOGU_BUST_3, ASSET.VOGU_BUST_4, ASSET.VOGU_BUST_5, ASSET.VOGU_BUST_6, ASSET.VOGU_BUST_7, ASSET.VOGU_BUST_8,
    ASSET.VOGU_BUST_9, ASSET.VOGU_BUST_10, ASSET.VOGU_BUST_11, ASSET.VOGU_BUST_12, ASSET.VOGU_BUST_13, ASSET.VOGU_BUST_14, ASSET.VOGU_BUST_15, ASSET.VOGU_BUST_16,
    ASSET.TAKE01, ASSET.TAKE02, ASSET.TAKE03, ASSET.TAKE04, ASSET.TAKE05, ASSET.TAKE06, ASSET.TAKE07, ASSET.TAKE08, ASSET.TAKE09, ASSET.TAKE10, ASSET.TAKE11, ASSET.TAKE12, ASSET.TAKE13, ASSET.TAKE14, ASSET.TAKE15, ASSET.TAKE16, ASSET.TAKE17, ASSET.TAKE18, ASSET.TAKE19, ASSET.TAKE20, ASSET.TAKE21, ASSET.TAKE22, ASSET.TAKE23, ASSET.TAKE24, ASSET.TAKE25, ASSET.TAKE26, ASSET.TAKE27, ASSET.TAKE28, ASSET.TAKE29, ASSET.TAKE30, ASSET.TAKE31, ASSET.TAKE32, ASSET.TAKE33, ASSET.TAKE34, ASSET.TAKE35, ASSET.TAKE36, ASSET.TAKE37, ASSET.TAKE38, ASSET.TAKE39, ASSET.TAKE40, ASSET.TAKE41, ASSET.TAKE42]

export const standaloneAssets: string[] = [
    ASSET.CUBE,
    ASSET.OMEGA,
    ASSET.ROBOT_BUST,
    ASSET.MECHA_CHAOTIC,
    ASSET.ASC05_SPOOKY,
    ASSET.GOODBOI,
    ASSET.GOODBOI_FAB,
]

export enum EXT {
    BGA = 'mp3',
    GLB = 'glb',
    HDR = 'hdr'
}

/**
 * Represents the directory structure of bucket root
 * @example
 * console.log(`${bucket}/${ASSET_TYPE.GLB}`)
 * // 'https://storage.googleapis.com/voxodeus/voxo_assets/glb'
 */
export enum ASSET_TYPE {
    BGA = 'bga',
    GLB = 'glb',
    HDRI = 'hdri'
}

export enum MP3 {
    A = 'Audio_Angos',
    B = 'Audio_Astras',
    C = 'Audio_Corpo',
    D = 'Audio_Verita',
    UR = 'Audio_Ultrarare',
    NO_THEME = 'No_Theme'
}

/**
 * An enumeration of effect attributes.
 * Attributes can be concatenated using the bitwise OR operator.
 *
 * @example
 * const attributes = EffectAttribute.CONVOLUTION | EffectAttribute.DEPTH;
 * @property CONVOLUTION - Describes effects that fetch additional samples from the input buffer. There cannot be more than one effect with this attribute per {@link EffectPass}.
 * @property DEPTH - Describes effects that require a depth texture.
 * @property NONE - No attributes. Most effects don't need to specify any attributes.
 */
export enum EffectAttribute {
    CONVOLUTION,
    DEPTH,
    NONE
}

export const urlParamAlias: { [key: string]: string } = {
    'JOINT': 'SPLIFF',
}

export const voxoTree = {
    "ROBITS": {
        "RH-01": {
            "A": {"R": 37, "SR": 9, "UR": 1},
            "B": {"R": 73, "SR": 18, "UR": 0},
            "C": {"R": 183, "SR": 44, "UR": 3},
            "D": {"R": 73, "SR": 18, "UR": 0}
        },
        "RH-02": {
            "A": {"R": 37, "SR": 9, "UR": 1},
            "B": {"R": 73, "SR": 18, "UR": 1},
            "C": {"R": 182, "SR": 44, "UR": 3},
            "D": {"R": 73, "SR": 18, "UR": 1}
        },
        "RH-03": {
            "A": {"R": 37, "SR": 9, "UR": 1},
            "B": {"R": 73, "SR": 18, "UR": 1},
            "C": {"R": 182, "SR": 44, "UR": 3},
            "D": {"R": 73, "SR": 18, "UR": 1}
        },
        "RS-01": {
            "A": {"R": 36, "SR": 9, "UR": 0},
            "B": {"R": 73, "SR": 17, "UR": 1},
            "C": {"R": 182, "SR": 43, "UR": 2},
            "D": {"R": 73, "SR": 17, "UR": 1}
        },
        "RS-02": {
            "A": {"R": 36, "SR": 9, "UR": 0},
            "B": {"R": 73, "SR": 17, "UR": 1},
            "C": {"R": 182, "SR": 43, "UR": 2},
            "D": {"R": 73, "SR": 17, "UR": 1}
        },
        "RS-03": {
            "A": {"R": 36, "SR": 9, "UR": 0},
            "B": {"R": 73, "SR": 17, "UR": 1},
            "C": {"R": 182, "SR": 43, "UR": 2},
            "D": {"R": 73, "SR": 17, "UR": 1}
        },
        "RL-01": {
            "A": {"R": 36, "SR": 8, "UR": 1},
            "B": {"R": 73, "SR": 17, "UR": 1},
            "C": {"R": 182, "SR": 43, "UR": 2},
            "D": {"R": 73, "SR": 17, "UR": 1}
        },
        "RL-02": {
            "A": {"R": 36, "SR": 8, "UR": 0},
            "B": {"R": 72, "SR": 17, "UR": 1},
            "C": {"R": 182, "SR": 43, "UR": 2},
            "D": {"R": 72, "SR": 17, "UR": 1}
        },
        "RL-03": {
            "A": {"R": 36, "SR": 8, "UR": 0},
            "B": {"R": 72, "SR": 17, "UR": 1},
            "C": {"R": 182, "SR": 42, "UR": 2},
            "D": {"R": 72, "SR": 17, "UR": 1}
        }
    },
    "SAPIENS": {
        "SM-01": {
            "A": {"R": 27, "SR": 7, "UR": 1},
            "B": {"R": 54, "SR": 13, "UR": 1},
            "C": {"R": 137, "SR": 32, "UR": 1},
            "D": {"R": 55, "SR": 13, "UR": 1}
        },
        "SM-02": {
            "A": {"R": 27, "SR": 7, "UR": 1},
            "B": {"R": 54, "SR": 13, "UR": 1},
            "C": {"R": 137, "SR": 32, "UR": 1},
            "D": {"R": 55, "SR": 13, "UR": 1}
        },
        "SM-03": {
            "A": {"R": 27, "SR": 7, "UR": 1},
            "B": {"R": 54, "SR": 13, "UR": 1},
            "C": {"R": 136, "SR": 32, "UR": 1},
            "D": {"R": 55, "SR": 13, "UR": 1}
        },
        "SS-01": {
            "A": {"R": 27, "SR": 7, "UR": 1},
            "B": {"R": 54, "SR": 13, "UR": 1},
            "C": {"R": 136, "SR": 32, "UR": 1},
            "D": {"R": 55, "SR": 13, "UR": 1}
        },
        "SS-03": {
            "A": {"R": 27, "SR": 7, "UR": 1},
            "B": {"R": 54, "SR": 13, "UR": 1},
            "C": {"R": 136, "SR": 32, "UR": 1},
            "D": {"R": 55, "SR": 13, "UR": 1}
        },
        "SS-04": {
            "A": {"R": 27, "SR": 7, "UR": 1},
            "B": {"R": 54, "SR": 13, "UR": 1},
            "C": {"R": 136, "SR": 32, "UR": 1},
            "D": {"R": 55, "SR": 13, "UR": 1}
        }
    },
    "AUGS": {
        "AL-01": {
            "A": {"R": 3, "SR": 1, "UR": 0},
            "B": {"R": 5, "SR": 1, "UR": 0},
            "C": {"R": 12, "SR": 3, "UR": 1},
            "D": {"R": 5, "SR": 1, "UR": 0}
        },
        "AL-02": {
            "A": {"R": 3, "SR": 1, "UR": 0},
            "B": {"R": 5, "SR": 1, "UR": 1},
            "C": {"R": 12, "SR": 3, "UR": 0},
            "D": {"R": 5, "SR": 1, "UR": 0}
        },
        "AL-04": {
            "A": {"R": 3, "SR": 1, "UR": 0},
            "B": {"R": 5, "SR": 1, "UR": 0},
            "C": {"R": 12, "SR": 3, "UR": 0},
            "D": {"R": 5, "SR": 1, "UR": 1}
        },
        "AXS-03": {
            "A": {"R": 3, "SR": 1, "UR": 1},
            "B": {"R": 5, "SR": 1, "UR": 0},
            "C": {"R": 12, "SR": 3, "UR": 0},
            "D": {"R": 5, "SR": 1, "UR": 0}
        },
    },
    "EXOS": {
        "EXL-01": {
            "A": {"R": 1, "SR": 1, "UR": 0},
            "B": {"R": 2, "SR": 0, "UR": 0},
            "C": {"R": 3, "SR": 0, "UR": 0},
            "D": {"R": 1, "SR": 0, "UR": 0}
        },
        "EXL-02": {
            "A": {"R": 1, "SR": 0, "UR": 0},
            "B": {"R": 2, "SR": 0, "UR": 0},
            "C": {"R": 3, "SR": 0, "UR": 0},
            "D": {"R": 1, "SR": 1, "UR": 0}
        },
        "EXL-03": {
            "A": {"R": 1, "SR": 0, "UR": 1},
            "B": {"R": 1, "SR": 1, "UR": 0},
            "C": {"R": 3, "SR": 0, "UR": 0},
            "D": {"R": 1, "SR": 0, "UR": 0}
        },
        "EXS-01": {
            "A": {"R": 1, "SR": 0, "UR": 0},
            "B": {"R": 2, "SR": 0, "UR": 0},
            "C": {"R": 2, "SR": 1, "UR": 0},
            "D": {"R": 2, "SR": 0, "UR": 0}
        },
    },
    "ASCENDII": {
        "ASC-01": {"A": {"UQ": 1}},
        "ASC-02": {"B": {"UQ": 1}},
        "ASC-03": {"D": {"UQ": 1}},
        "ASC-04": {"C": {"UQ": 1}},
        "ASC-05": {"A": {"UQ": 1}},
        "ASC-06": {"B": {"UQ": 1}},
        "ASC-07": {"D": {"UQ": 1}},
        "ASC-08": {"C": {"UQ": 1}}
    },
    "OMEGA": {
        "OXO-01": 1
    }
}
