import create from 'zustand'
import styled from 'styled-components'
import React from 'react'

import { VEC3 } from '../../utils'

type LoggerProps = {

}

const Logger: React.FC<LoggerProps> = () => {
    const [camloc, camlookat, fov] = useLoggerStore(state => [state.camloc, state.camlookat, state.fov])
    return <LogButton onClick={() => {
        console.log('Camera', { camloc, camlookat, fov })
    }}>Log Viewer States</LogButton>
}

const LogButton = styled.button`
    position: absolute;
    top: 60px;
    border-radius: 3px;
    padding: 6px 10px;
    font-family: monospace;
    font-weight: bold;
    color: ghostwhite;
    background-color: #6d35d4;
    cursor: pointer;
    outline: none;
    border: none;
`

type CameraDataLog = {
    camloc: VEC3
    camlookat: VEC3
    fov: number
}

type LoggerStore = CameraDataLog & {
    setCameraDataLog: (cameraData: CameraDataLog) => void
}

export const useLoggerStore = create<LoggerStore>(set => ({
    camloc: [0, 0, 0],
    camlookat: [0, 0, 0],
    fov: 0,
    setCameraDataLog: ({ camloc, camlookat, fov }) => set({ camloc: camloc, camlookat: camlookat, fov: fov })
}))

export default Logger