import React from 'react'
import { PMREMGenerator, CubeTextureLoader } from 'three'
import { useThree, useLoader } from '@react-three/fiber'
import { presetsObj } from '@react-three/drei/helpers/environment-assets'
import { RGBELoader } from 'three-stdlib'
import { useAsset } from 'use-asset'

import { ASSET, bucket, EXT } from '../../constants'

function getTexture(texture, gen, isCubeMap) {
    if (isCubeMap) {
        gen.compileEquirectangularShader()
        return gen.fromCubemap(texture).texture
    }

    return gen.fromEquirectangular(texture).texture
}

const CUBEMAP_ROOT = 'https://rawcdn.githack.com/pmndrs/drei-assets/aa3600359ba664d546d05821bcbca42013587df2'

function extendPreset(extension) {
    return Object.assign({}, presetsObj, extension)
}

function Environment({
    background = false,
    files = ['/px.png', '/nx.png', '/py.png', '/ny.png', '/pz.png', '/nz.png'],
    path = '',
    preset = undefined,
    scene,
    extensions,
    active
}) {
    if (preset) {
        const extendedPreset = extendPreset({
            bridge: `${ASSET.BRIDGE}.${EXT.HDR}`,
            winter: `${ASSET.WINTER}.${EXT.HDR}`,
            whirlwind: `${ASSET.WHIRLWIND}.${EXT.HDR}`,
            snow: `${ASSET.SNOW}.${EXT.HDR}`
        })

        if (!(preset in extendedPreset)) {
            throw new Error('Preset must be one of: ' + Object.keys(extendedPreset).join(', '))
        }

        files = extendedPreset[preset]
        path = preset in presetsObj ?
            CUBEMAP_ROOT + '/hdri/' :
            `${bucket}/hdri/`
        // https://storage.googleapis.com/voxodeus/voxo_assets
    }

    const defaultScene = useThree(({ scene }) => scene)
    const gl = useThree(({ gl }) => gl)
    const isCubeMap = Array.isArray(files)
    const loader = isCubeMap ? CubeTextureLoader : RGBELoader // @ts-expect-error
    const loaderResult = useLoader(loader, isCubeMap ? [files] : files, loader => {
        loader.setPath(path)
        if (extensions) extensions(loader)
    })
    const map = isCubeMap ? loaderResult[0] : loaderResult // PMREMGenerator takes its sweet time to generate the env-map,
    // Let's make this part of suspense, or else it just yields a frame-skip

    const texture = useAsset(() => new Promise(res => {
        const gen = new PMREMGenerator(gl)
        const texture = getTexture(map, gen, isCubeMap)
        gen.dispose()
        res(texture)
    }), map)
    React.useLayoutEffect(() => {
        if (!active) return

        const oldbg = scene ? scene.background : defaultScene.background
        const oldenv = scene ? scene.environment : defaultScene.environment

        if (scene) {
            scene.environment = texture
            if (background) scene.background = texture
        }
        else {
            defaultScene.environment = texture
            if (background) defaultScene.background = texture
        }

        return () => {
            if (scene) {
                scene.environment = oldenv
                scene.background = oldbg
            } else {
                defaultScene.environment = oldenv
                defaultScene.background = oldbg
            } // Environment textures are volatile, better dispose and uncache them


            useAsset.clear(map)
            texture.dispose()
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [texture, background, scene, active])
    return null
}

export { Environment }
