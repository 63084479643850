import {
    WebGLRenderTarget, ShaderMaterial,
    LinearFilter, RGBAFormat, Vector2
} from 'three';

export default class bloomEffectPass {
    postScene: any;
    renderer: any;
    postCamera: any;
    matdictionary: any;
    quad: any;
    sceneColor: any;
    renderTargetsHorizontal: any;
    renderTargetsVertical: any;
    nMips: any;
    resolution: any
    separableBlurMaterials: any
    compositeMaterial: any

    constructor(scene: any, camera: any, renderer: any, quad: any) {

        let pars = {
            minFilter: LinearFilter,
            magFilter: LinearFilter,
            format: RGBAFormat
        };
        this.renderTargetsHorizontal = [];
        this.renderTargetsVertical = [];
        this.nMips = 5;
        this.resolution = new Vector2(window.innerWidth, window.innerHeight);
        let resx = Math.round(this.resolution.x / 2);
        let resy = Math.round(this.resolution.y / 2);

        for (let i = 0; i < this.nMips; i++) {
            let renderTargetHorizonal = new WebGLRenderTarget(resx, resy, pars);

            renderTargetHorizonal.texture.name = "UnrealBloomPass.h" + i;
            renderTargetHorizonal.texture.generateMipmaps = false;

            this.renderTargetsHorizontal.push(renderTargetHorizonal);

            let renderTargetVertical = new WebGLRenderTarget(resx, resy, pars);

            renderTargetVertical.texture.name = "UnrealBloomPass.v" + i;
            renderTargetVertical.texture.generateMipmaps = false;

            this.renderTargetsVertical.push(renderTargetVertical);

            resx = Math.round(resx / 2);

            resy = Math.round(resy / 2);
        }

        this.separableBlurMaterials = [];
        let kernelSizeArray = [3, 5, 7, 9, 11];

        resx = Math.round(this.resolution.x / 2);
        resy = Math.round(this.resolution.y / 2);

        for (let i = 0; i < this.nMips; i++) {
            this.separableBlurMaterials.push(
                this.getSeperableBlurMaterial(kernelSizeArray[i])
            );

            this.separableBlurMaterials[i].uniforms[
                "texSize"
            ].value = new Vector2(resx, resy);

            resx = Math.round(resx / 2);

            resy = Math.round(resy / 2);
        }
        this.compositeMaterial = this.getCompositeMaterial(this.nMips);
        this.compositeMaterial.uniforms[
            "blurTexture1"
        ].value = this.renderTargetsVertical[0].texture;
        this.compositeMaterial.uniforms[
            "blurTexture2"
        ].value = this.renderTargetsVertical[1].texture;
        this.compositeMaterial.uniforms[
            "blurTexture3"
        ].value = this.renderTargetsVertical[2].texture;
        this.compositeMaterial.uniforms[
            "blurTexture4"
        ].value = this.renderTargetsVertical[3].texture;
        this.compositeMaterial.uniforms[
            "blurTexture5"
        ].value = this.renderTargetsVertical[4].texture;
        this.compositeMaterial.uniforms["bloomStrength"].value = 1.;
        this.compositeMaterial.uniforms["bloomRadius"].value = 1.;
        this.compositeMaterial.needsUpdate = true;

        let bloomFactors = [1.0, 0.8, 0.6, 0.4, 0.2];
        this.compositeMaterial.uniforms["bloomFactors"].value = bloomFactors;
     

        this.postScene = scene;
        this.renderer = renderer;
        this.postCamera = camera;
        this.quad = quad;


    }


    resize(res_x: any, res_y: any) {
        let resx = Math.round(res_x / 2);
        let resy = Math.round(res_y / 2);

        for (let i = 0; i < this.nMips; i++) {
            this.renderTargetsHorizontal[i].setSize(resx, resy);
            this.renderTargetsVertical[i].setSize(resx, resy);

            this.separableBlurMaterials[i].uniforms[
                "texSize"
            ].value = new Vector2(resx, resy);

            resx = Math.round(resx / 2);
            resy = Math.round(resy / 2);
        }
    }
    update(target: any, bloomRadius: any, bloomStrength: any) {
        let inputRenderTarget = target;

        for (let i = 0; i < this.nMips; i++) {
            this.quad.material = this.separableBlurMaterials[i];

            this.separableBlurMaterials[i].uniforms["colorTexture"].value =
                inputRenderTarget.texture;
            this.separableBlurMaterials[i].uniforms[
                "direction"
            ].value = new Vector2(1.0, 0.0);
            this.renderer.setRenderTarget(this.renderTargetsHorizontal[i]);
            this.renderer.clear();
            this.renderer.render(this.postScene, this.postCamera);
            this.renderer.setRenderTarget(null);

            this.separableBlurMaterials[i].uniforms[
                "colorTexture"
            ].value = this.renderTargetsHorizontal[i].texture;
            this.separableBlurMaterials[i].uniforms[
                "direction"
            ].value = new Vector2(0.0, 1.0);
            this.renderer.setRenderTarget(this.renderTargetsVertical[i]);
            this.renderer.clear();
            this.renderer.render(this.postScene, this.postCamera);
            this.renderer.setRenderTarget(null);

            inputRenderTarget = this.renderTargetsVertical[i];
        }

        this.quad.material = this.compositeMaterial;
        this.compositeMaterial.uniforms["bloomStrength"].value = bloomStrength;
        this.compositeMaterial.uniforms["bloomRadius"].value = bloomRadius;
  

        this.renderer.setRenderTarget(this.renderTargetsHorizontal[0]);
        this.renderer.clear();
        this.renderer.render(this.postScene, this.postCamera);
        this.renderer.setRenderTarget(null);
    }

    getSeperableBlurMaterial(kernelRadius: any) {
        return new ShaderMaterial({
            defines: {
                KERNEL_RADIUS: kernelRadius,
                SIGMA: kernelRadius
            },

            uniforms: {
                colorTexture: { value: null },
                texSize: { value: new Vector2(0.5, 0.5) },
                direction: { value: new Vector2(0.5, 0.5) }
            },

            vertexShader: `
                varying vec2 vUv;
                void main() {
                    vUv = uv;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }`,

            fragmentShader: `
                #include <common>
                varying vec2 vUv;
                uniform sampler2D colorTexture;
                uniform vec2 texSize;
                uniform vec2 direction;
                
                float gaussianPdf(in float x, in float sigma) {
                    return 0.39894 * exp( -0.5 * x * x/( sigma * sigma))/sigma;
                }

                void main() {
                    vec2 invSize = 1.0 / texSize;
                    float fSigma = float(SIGMA);
                    float weightSum = gaussianPdf(0.0, fSigma);
                    vec3 diffuseSum = texture2D( colorTexture, vUv).rgb * weightSum;
                    for( int i = 1; i < KERNEL_RADIUS; i ++ ) {
                        float x = float(i);
                        float w = gaussianPdf(x, fSigma);
                        vec2 uvOffset = direction * invSize * x;
                        vec3 sample1 = texture2D( colorTexture, vUv + uvOffset).rgb;
                        vec3 sample2 = texture2D( colorTexture, vUv - uvOffset).rgb;
                        diffuseSum += (sample1 + sample2) * w;
                        weightSum += 2.0 * w;
                    }
                    gl_FragColor = vec4(diffuseSum/weightSum, 1.0);
                }`
        });
    }

    getCompositeMaterial(nMips: any) {
        return new ShaderMaterial({
            defines: {
                NUM_MIPS: nMips
            },

            uniforms: {
                blurTexture1: { value: null },
                blurTexture2: { value: null },
                blurTexture3: { value: null },
                blurTexture4: { value: null },
                blurTexture5: { value: null },
                dirtTexture: { value: null },
                bloomStrength: { value: 1.0 },
                bloomFactors: { value: null },
               
                bloomRadius: { value: 1.0 }
            },

            vertexShader: `
                varying vec2 vUv;
                void main() {
                    vUv = uv;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }`,

            fragmentShader: `
                varying vec2 vUv;
                uniform sampler2D blurTexture1;
                uniform sampler2D blurTexture2;
                uniform sampler2D blurTexture3;
                uniform sampler2D blurTexture4;
                uniform sampler2D blurTexture5;
                uniform sampler2D dirtTexture;
                uniform float bloomStrength;
                uniform float bloomRadius;
                uniform float bloomFactors[NUM_MIPS];
              
                
                void main() {
                    gl_FragColor = bloomStrength * ( bloomFactors[0]  * texture2D(blurTexture1, vUv) + 
                   bloomFactors[1] * texture2D(blurTexture2, vUv) + 
                    bloomFactors[2] * texture2D(blurTexture3, vUv) + 
                    bloomFactors[3] * texture2D(blurTexture4, vUv) + 
                    bloomFactors[4] * texture2D(blurTexture5, vUv) );
                }`
        });
    }
}