import {
    WebGLRenderTarget, DepthTexture, Mesh,
    Vector3, ShaderMaterial, Color
} from 'three'
import { Shaders } from '../Shader.js'

export default class glowmaskPass {
    //ren: WebGLRenderTarget;
    glowTarget: WebGLRenderTarget
    lightShader: ShaderMaterial
    scene: any
    renderer: any
    camera: any
    matdictionary: any
    sceneColor: any

    constructor(scene: any, camera: any, renderer: any, groundlight: any) {

        const shaders = new Shaders()
        this.glowTarget = new WebGLRenderTarget(
            window.innerWidth,
            window.innerHeight
        )
        this.glowTarget.depthBuffer = true
        this.glowTarget.depthTexture = new DepthTexture(window.innerWidth, window.innerHeight)
        let lightUniforms = {
            color: {
                value: [] as any
            },
            pos: {
                value: [] as any
            },
            strength: {
                value: null as any
            },
            alpha: {
                value: null as any
            }
        }
        /*
        scene.traverse(function (node: any) {
            const c = node.children as any
            console.log(c);
            if (node instanceof Mesh) {

                let mat = node.material as any;
                if (mat.name.charAt(0) as any == 'E') {
                    let n = new Vector3();
                    let { r, g, b } = mat.emissive || mat.color;

                    node.getWorldPosition(n);
                    n.y = -1.5;
                    lightUniforms.pos.value.push(n);
                    // node.material.fog = false;
                    lightUniforms.color.value.push(new Vector3(r, g, b));

                }
            }
        });*/

        if (lightUniforms.pos.value.length < 3) {
            lightUniforms.pos.value.push(new Vector3(0, 0, 0))
            lightUniforms.pos.value.push(new Vector3(0, 0, 0))
            lightUniforms.pos.value.push(new Vector3(0, 0, 0))
            lightUniforms.color.value.push(new Vector3(0, 0, 0))
            lightUniforms.color.value.push(new Vector3(0, 0, 0))
            lightUniforms.color.value.push(new Vector3(0, 0, 0))
        }
        lightUniforms.pos.value[0].x = 1.7
        lightUniforms.pos.value[0].z = -1.0
        lightUniforms.pos.value[1].z = -0.5
        lightUniforms.pos.value[1].x = -1.5
        lightUniforms.pos.value[2].z = 0.5
        this.lightShader = new ShaderMaterial({
            uniforms: lightUniforms,
            fragmentShader: shaders.lightShader(),
            vertexShader: shaders.vertexShader(),
            transparent: true
        })
        this.lightShader.uniforms.strength.value = groundlight

        this.scene = scene
        this.renderer = renderer
        this.camera = camera
        this.matdictionary = {} as any
        this.sceneColor = this.scene.background
        //console.log(this.ren);
        // this.ren = new WebGLRenderTarget(1, 2);

    }

    loopScene(children: any) { }

    resize(w: any, h: any) {
        this.glowTarget.setSize(w, h)
    }

    update(cam: any) {
        const doSwap = (mat: any) => {
            return mat.type !== 'MeshPhysicalMaterial' &&
                (!(mat.emissive && Object.values(mat.emissive).some(ch => ch)) || mat.type === 'ShadowMaterial')
        }
        this.scene.background = new Color('black')
        this.renderer.setRenderTarget(this.glowTarget)
        this.matdictionary = {}
        this.scene.traverse((node: any) => {
            if (node instanceof Mesh) {
                let mat = node.material as any

                if (mat.type === 'ShadowMaterial') {
                    // console.log(mat)
                }
                if (doSwap(mat)) {
                    let n = mat.name
                    this.matdictionary[n] = node.material
                    //node.material = new THREE.MeshBasicMaterial({ color: 0x000000 });
                    // let opa = mat.opacity;
                    mat = this.lightShader.clone() as ShaderMaterial

                    mat.uniforms.alpha.value = mat.type === 'ShadowMaterial' ? 0 : 1
                    mat.name = n
                    node.material = mat
                    //node.material = mat2;
                    //this.scene.remove(node);
                }
            }
        })

        this.renderer.render(this.scene, cam)
        this.renderer.setRenderTarget(null)
        this.scene.traverse((node: any) => {
            if (node instanceof Mesh) {
                const mat = node.material as any

                if (doSwap(mat)) {
                    //let n = node.material.name;
                    //this.matdictionary[n] = node.material;
                    node.material = this.matdictionary[mat.name]
                    //node.material.name = n;
                    //this.scene.remove(node);
                }
            }
        })
        this.scene.background = this.sceneColor
    }
}