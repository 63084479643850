import { WebGLMultisampleRenderTarget, WebGLRenderTarget } from 'three';

export default class sceneRenderPass {
    constructor(scene, camera, renderer) {
        this.capacity = renderer.capabilities.isWebGL2;
        //////////Render Target for original scene/////////////
        if (this.capacity) {
            this.renderTarget = new WebGLMultisampleRenderTarget(
                window.innerWidth,
                window.innerHeight
            );
            // this.renderTarget.antialias = true;
            this.renderTarget.samples = 32;
        }
        else {
            this.renderTarget = new WebGLRenderTarget(
                window.innerWidth,
                window.innerHeight
            );
        }

        this.scene = scene;
        this.renderer = renderer;
        this.camera = camera;
        this.sceneColor = this.scene.background;
    }

    dispose() {
        this.renderTarget.dispose();
    }

    resize(w, h) {
        this.renderTarget.setSize(w, h);
    }

    update(offlinePass, cam) {
        if (offlinePass) {
            this.renderer.setRenderTarget(this.renderTarget);
            this.renderer.render(this.scene, cam);
            this.renderer.setRenderTarget(null);
        }
        else {
            this.renderer.render(this.scene, cam);
        }
    }
}