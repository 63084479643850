import { ShaderMaterial, Vector2 } from 'three';
import { Shaders } from '../Shader.js';

export default class fxaaEffect {
    //ren: WebGLRenderTarget;

    postScene: any;
    renderer: any;
    postCamera: any;
    matdictionary: any;
    quad: any;
    sceneColor: any;
    FXAA: any;
    constructor(scene: any, camera: any, renderer: any, quad: any) {

        let shaders = new Shaders();

        let FXAAuniforms = {
            tDiffuse: {
                value: null
            },
            resolution: {
                value: new Vector2()
            }
        };


        this.FXAA = new ShaderMaterial({
            uniforms: FXAAuniforms,
            fragmentShader: shaders.FXAA(),
            vertexShader: shaders.vertexShader()
        });

        this.postScene = scene;
        this.renderer = renderer;
        this.postCamera = camera;
        this.quad = quad;

    }
    resize(w: any, h: any) {
        // this.glowTarget.setSize(w, h);
    }
    update(renderTexture: any) {
        var pixelRatio = this.renderer.getPixelRatio();
        this.quad.material = this.FXAA;
        this.quad.material.uniforms.tDiffuse.value = renderTexture;
        this.quad.material.uniforms.resolution.value.x =
            1 / (window.innerWidth * pixelRatio);
        this.quad.material.uniforms.resolution.value.y =
            1 / (window.innerHeight * pixelRatio);
        this.renderer.render(this.postScene, this.postCamera);
    }
}