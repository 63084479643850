import React, { useState } from 'react'
import styled from 'styled-components'

import { DevProps, specialNaming } from '../../constants'
import { toTitle } from '../../utils'

export type ToggleProps = DevProps & {
    asset?: any
    label?: string
    description?: string
    init: boolean
    on: string
    onTooltip?: string
    off?: string
    offTooltip?: string
    toggleOnHover?: boolean
    onToggle?: (isOn?: boolean) => void
}

const Trigger = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-auto-flow: column;
    grid-template-columns: min-content max-content;
    place-items: start;
    font-family: Raleway;
    font-size: 13px;
    color: #0e1c34;
    cursor: pointer;
    transition: opacity 0.2s ease-in;
    z-index: 99;
    outline: none;
    word-break: keep-all;
    user-select: none;
`
const Icon = styled.img`
    padding-top: 2px;
    outline: none;
    user-select: none;
`
const Rows = styled.div`
    display: grid;
`
const Description = styled.span`
    font-size: 11px;
    padding: 0 5px;
    opacity: 0.7;
`
const Toggle: React.FC<ToggleProps> = props => {
    const { asset, label, description, init, toggleOnHover, on, off, onToggle, testId } = props
    const [hover, toggleHover] = useState(false)
    const src = off ? (init ? on : off) : on
    const Label = () => <span>{label}</span>
    const ToggleText = () => {
        if (label) {
            if (description) {
                return <Rows>
                    <span >
                        {label}
                        <Description id={`${testId}Description`}>{
                            specialNaming.reduce((acc, item) => acc.replace(item.test, item.replace), toTitle(description))
                        }</Description>
                    </span>
                </Rows>
            }
            return <Label />
        }
        return <></>
    }

    return (asset || asset === undefined) ?
        <Trigger
            id={testId}
            style={{ opacity: init ? 1 : hover ? 0.5 : 0.3 }}
            onMouseOver={() => {
                toggleHover(true)
                toggleOnHover && !init && onToggle && onToggle()
            }}
            onMouseLeave={() => toggleHover(false)}
            onPointerUp={() => onToggle && onToggle()}
        >
            <Icon src={src} alt={src} />
            <ToggleText />
        </Trigger> :
        <></>
}

export default Toggle