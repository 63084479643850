import React from 'react';

import Glb from './Glb';
import { useViewerStore } from './Viewer';
import { useVoxoStore } from './Voxo';

import { ASSET, GLB_TYPE } from '../constants';

const LightRig: React.FC = () => {
    const [cubeActive] = useVoxoStore(state => [state.cubeActive]);
    const [lightRig, voxoName] = useViewerStore(state => [state.urlReq.light, state.urlReq.voxoName]);
    const cubeRig = cubeActive || voxoName === ASSET.CUBE;

    return (<>
        <Glb filename={lightRig || 'SUN'} subcategory={GLB_TYPE.LIGHT_RIG} visible={!cubeRig} />
        <Glb filename='CUBE' subcategory={GLB_TYPE.LIGHT_RIG} visible={cubeRig} />
    </>);
};

export default LightRig;