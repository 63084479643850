import React, { useEffect, useRef } from 'react'
import { GLTFLoader } from 'three-stdlib'
import { AnimationMixer, BufferGeometry, Color, DirectionalLight, Group, Mesh, MeshStandardMaterial, Texture, Vector3 } from 'three'

import AssetLoader from './AssetLoader'
import { useLoaderStore } from './Loading'
import { GearState, getPath, swapAxis } from '../utils'
import { ASSET_TYPE, envNameMap, GLB_TYPE } from '../constants'
import { useFrame } from '@react-three/fiber'

type GlbProps = {
    filename: string
    subcategory: GLB_TYPE
    env?: string
    archetype?: string
    initPos?: Vector3 | number[]
    initScl?: Vector3 | number[]
    offset?: number
    visible?: boolean
    settings?: GlbSettings
}
type GlbSettings = {
    onclick?: () => void
    gearActive?: GearState
    material?: {
        roughness?: number
        metalness?: number
        map?: Texture
    } & any,
    diamond?: boolean
}

type DiamondProps = { geometry: BufferGeometry }

const Diamond: React.FC<DiamondProps> = ({ geometry }) => {
    const materialProps = {
        thickness: 3,
        roughness: 0,
        clearcoat: 0.3,
        clearcoatRoughness: 0.6,
        transmission: 1,
        ior: 1.8,
        envMapIntensity: 1.8,
        color: new Color('white')
    }

    return <group>
        <mesh geometry={geometry} rotation={[Math.PI / 2, 0, 0]}>
            <meshPhysicalMaterial {...materialProps} />
        </mesh>
    </group>
}

const Glb: React.FC<GlbProps> = ({ filename, subcategory, env, archetype, initPos, initScl, offset, visible = true, settings = {} }) => {
    const setProgress = useLoaderStore(state => state.setProgress)
    const assetName = `${subcategory}/${filename}`
    const glb = AssetLoader(
        GLTFLoader,
        getPath(ASSET_TYPE.GLB, subcategory, filename),
        undefined,
        xhr => setProgress(assetName, xhr.loaded / xhr.total)
    )
    const envNames = Object.values(envNameMap).reduce((acc, group) => {
        acc = Object.assign(acc, group)
        return acc
    }, {} as { [newName: string]: string })
    const oldEnvName = env && envNames[env]
    const userData = glb && glb.scene.children[0].userData
    const coords = userData && (
        (oldEnvName && oldEnvName in userData && userData[oldEnvName]) ||
        (archetype && archetype in userData && userData[archetype])
    )
    const position = (coords && swapAxis(coords, offset)) || initPos
    const { scene, gearGlbs } = subcategory === GLB_TYPE.HERO ?
        applyGearSettings(glb.scene, settings.gearActive || {}, position, visible, settings) : {
            scene: glb.scene,
            gearGlbs: []
        }
    const meshes = Object.values(glb.nodes).filter(n => n instanceof Mesh).map(n => n as Mesh)
    let mixer = useRef<AnimationMixer>()

    scene.name = subcategory

    useEffect(() => {
        if (glb.animations.length) {
            mixer.current = new AnimationMixer(glb.scene)
            glb.animations.forEach(clip => mixer.current && mixer.current.clipAction(clip).play())
        }
    }, [glb])

    useFrame((_, delta) => mixer.current && mixer.current.update(delta))

    return glb ? (subcategory === GLB_TYPE.DIAMOND ? <Diamond geometry={meshes[0].geometry} /> : <>
        <primitive
            object={applyMaterialSettings(scene, visible, settings)}
            position={position}
            scale={initScl || new Vector3(1, 1, 1)}
            visible={visible}
            onPointerUp={settings.onclick}
        />
        {gearGlbs}
    </>) : <></>
}

function applyGearSettings(
    scene: Group,
    gear: GearState,
    position: Vector3 | number[],
    visible: boolean,
    settings: GlbSettings
) {
    const gearGlbs = [] as any
    const gearSlots = Object.keys(gear)

    scene.children = scene.children.filter(child => {
        if (gearSlots.includes(child.name)) {
            console.log('-------------------------------', child.name)

            const gearOptions = Object.entries(gear[child.name])

            return !gearOptions.some(([option, state]) => {
                console.log(option, state)
                if (state)
                    gearGlbs.push(
                        <Glb
                            filename={option}
                            subcategory={GLB_TYPE.GEAR}
                            initPos={position}
                            visible={visible}
                            settings={settings}
                        />
                    )
                return state
            })
        }
        return true
    })

    return { scene, gearGlbs }
}

function applyMaterialSettings(group: Group, visible: boolean, settings: GlbSettings) {
    const { roughness, metalness } = settings.material || {}
    const editMaterial = (material: MeshStandardMaterial) => {
        material.needsUpdate = true
        material.dithering = true
        material.roughness = roughness === undefined ? 1 : roughness

        /**
         * TODO: implement better glass shader
         * @see https://codesandbox.io/s/glas-transmission-fresnel-enx1u
         */
        if (/^G\d{2}-/.test(material.name) || material.opacity < 1) {
            //  TODO: disable for adjusted glb files
            // material.opacity = 0.6
            material.transparent = true
        }
        else if (/^M\d{2}-/.test(material.name)) {
            material.metalness = 0.75
            material.roughness = 0.6
        }
        else if (/^E\d{2}-/.test(material.name)) {
            material.metalness = 0
            material.roughness = 1
        }
        else
            material.metalness = metalness === undefined ? 0 : metalness

        //  rename material to avoid clash with other asset materials
        material.name = `${material.name}#${material.uuid}`
    }

    //  traverse object tree
    group.children.forEach(child => {
        if (child instanceof Mesh) {
            const mat = child.material as MeshStandardMaterial | MeshStandardMaterial[]

            if (/RENDER|PLANE(_\d)?/i.test(child.name))
                child.castShadow = false
            else child.castShadow = true

            child.visible = visible
            Array.isArray(mat) ? mat.forEach(editMaterial) : editMaterial(mat)
        }
        else if (child instanceof DirectionalLight) {
            const frustum = 15

            child.castShadow = true

            Object.assign(child.shadow.camera, {
                top: frustum,
                bottom: -frustum,
                left: -frustum,
                right: frustum
            })
        }
        else if (child.children.length)
            applyMaterialSettings(child as Group, visible, settings)
    })

    return group
}

export default Glb