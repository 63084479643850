import React, { useState } from 'react';
import { Mesh, Scene, OrthographicCamera, PlaneBufferGeometry } from 'three';
import { useThree, useFrame } from '@react-three/fiber';

import glowmaskPass from '../effects/glowmaskPass';
import fxaaEffect from '../effects/fxaaEffect';
import bloomEffect from '../effects/bloomEffect';
import sceneRenderPass from '../effects/sceneRenderPass';
import compositePass from '../effects/compositePass';
import postEffectPass from '../effects/postEffects';

type EffectManagerProps = {
    //  opacity, extent, intensity
    vig?: number[]
}

const EffectManager: React.FC<EffectManagerProps> = props => {
    const { scene, camera, gl } = useThree();
    const [fx, setFx] = useState({} as any);

    if (!fx.postEffect) {
        const postScene = new Scene();
        //post processing orthogonal camera
        const postCamera = new OrthographicCamera(
            -1, // left
            1, // right
            1, // top
            -1, // bottom
            -1, // near
            1 // far
        );
        const plane = new PlaneBufferGeometry(2, 2);
        //quad node
        const quad = new Mesh(plane, null as any);
        const vig = props.vig && {
            opacity: props.vig[0],
            extent: props.vig[1],
            intensity: props.vig[2]
        };

        //making quad fit the post camera
        quad.position.z = -1;
        //adding quad node to post processing scene
        postScene.add(quad);

        setFx({
            scenePass: new sceneRenderPass(scene, camera, gl),
            glowpass: new glowmaskPass(scene, camera, gl, 1.0),
            fxaa: new fxaaEffect(postScene, postCamera, gl, quad),
            bloom: new bloomEffect(postScene, postCamera, gl, quad),
            composite: new compositePass(postScene, postCamera, gl, quad),
            postEffect: new postEffectPass(postScene, camera, postCamera, gl, quad, vig)
        });
    }
    else {
        fx.glowpass.resize(window.innerWidth, window.innerHeight);
        fx.bloom.resize(window.innerWidth, window.innerHeight);
        fx.postEffect.resize(window.innerWidth, window.innerHeight);
        fx.composite.resize(window.innerWidth, window.innerHeight);
    }

    useFrame(() => {
        fx.scenePass.update(1, camera);
        fx.glowpass.update(camera);
        fx.bloom.update(fx.glowpass.glowTarget, 0.5, 0.5);
        fx.composite.update(fx.scenePass.renderTarget.texture, fx.bloom.renderTargetsHorizontal[0].texture)
        fx.postEffect.update(fx.composite.compositeTarget.texture, true)
        fx.fxaa.update(fx.postEffect.posttarget.texture);
    }, 1)

    return <>{props.children}</>;
};

export default EffectManager;