import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Html } from '@react-three/drei';

import { useViewerStore } from './Viewer';
import create from 'zustand';

type LoaderProps = { y?: number }
type ContentSize = { [asset: string]: number }
type LoaderStore = {
    pct: ContentSize
    setProgress: (asset: string, size: number) => void
    clearProgress: () => void
}

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    background-color: #0E1C34;
`;
const Track = styled.div`
    width: 200px;
    height: 6px;
    align-self: end;
    margin-bottom: 10px;
    border-radius: 3px;
    z-index: 99;
    background-color: #42427C;
    /* opacity: 0.5; */
`;
const Bar = styled.div`
    width: 0%;
    height: 100%;
    border-radius: 3px;
    background-color: #DEDCED;
    box-shadow: 1px 2px 30px 5px rgba(215, 89, 123, 0.5);
    transition: width 0.2s ease-in-out;
`;
const LoadingLabel = styled.span`
    align-self: start;
    margin-top: 10px;
    font-family: 'IBM Plex Sans';
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #BDB9DD;
    opacity: 0.5;
`;
const Loading: React.FC<LoaderProps> = () => {
    const cameraControl = useViewerStore(state => state.cameraControl);
    const pct = useLoaderStore(state => state.pct);
    const clearProgress = useLoaderStore(state => state.clearProgress);
    const percentages = Object.values(pct);
    const percentage = percentages.length ?
        (percentages.reduce((a, b) => a + b) / percentages.length) : 0;
    const displayPercentage = (percentage > 0.95 ? 1 : percentage) * 100;

    useEffect(clearProgress, [clearProgress]);

    return <Html center position={cameraControl.target}><Wrapper>
        <Track>
            <Bar style={{ width: `${displayPercentage}%`, transition: displayPercentage ? 'width 0.2s linear' : 'none' }} />
        </Track>
        <LoadingLabel id='loading'>Loading model</LoadingLabel>
    </Wrapper></Html>;
};

export const useLoaderStore = create<LoaderStore>(set => ({
    pct: {},
    setProgress: (asset, pct) => set(state => ({ pct: { ...state.pct, [asset]: pct } })),
    clearProgress: () => set(({ pct: {} }))
}));

export default Loading;