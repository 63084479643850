import React, { useEffect } from 'react'
import create from 'zustand'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import ReactAudioPlayer from 'react-audio-player'
import { OrbitControls } from 'three-stdlib/controls/OrbitControls'

import ThreeCanvas from './ThreeCanvas'
import { useVoxoStore } from './Voxo'
import Toggle from './ui/Toggle'
import ViewInAR from './ui/ViewInAR'
import MenuToggle, { useMenuStore } from './ui/MenuToggle'

import { getArchetypes, getPath, parseRequest, UrlParseResult, VEC3 } from '../utils'
import { ASSET_TYPE, ASSET, MP3, RARITY, GLB_TYPE, bucket, standaloneAssets, bustAssets, defaultCameraLoc, defaultCameraLookat, defaultFov, mode } from '../constants'

import vol_off from '../assets/img/vol_off.png'
import vol_on from '../assets/img/vol_on.png'
import vol_on_tooltip from '../assets/img/vol_on_tooltip.svg'
import vol_off_tooltip from '../assets/img/vol_off_tooltip.svg'
import Logger from './ui/Logger'

type CameraData = {
    loc?: VEC3
    lookat?: VEC3
    fov?: number
}

type ViewerStore = {
    name: string
    token_id: string
    cameraData: {
        loc: VEC3
        lookat: VEC3
        fov: number
    }
    cameraControl: OrbitControls
    urlReq: UrlParseResult["urlReq"]
    setTokenId: (token_id: string) => void
    setName: (name: string) => void
    setCamera: (cameraData: CameraData) => void
    setCameraControl: (ctrl: OrbitControls) => void
    setUrlReq: (reqData: ViewerStore['urlReq']) => void
}

const ControlPanel: React.FC<{ showMenu: boolean }> = props => {
    const toggleAudio = useVoxoStore(state => state.toggleAudio)
    const token_id = useViewerStore(state => Number(state.token_id))
    const [audio, audioActive] = useVoxoStore(state => [
        state.audio,
        state.audioActive
    ])

    return <ControlWrapper>
        {props.showMenu && <MenuToggle />}
        <Toggle
            asset={audio}
            init={audioActive}
            on={vol_on}
            off={vol_off}
            onTooltip={vol_on_tooltip}
            offTooltip={vol_off_tooltip}
            onToggle={toggleAudio}
        />
        {token_id > 0 && token_id < 6313 && <ViewInAR voxoId={token_id} />}
    </ControlWrapper>
}

const BGA: React.FC = () => {
    const [audio, audioActive] = useVoxoStore(state => [
        state.audio,
        state.audioActive
    ])

    return audio ? <ReactAudioPlayer
        loop
        src={audio}
        muted={!audioActive}
        onVolumeChanged={({ target }) => {
            if (target) {
                const el = target as HTMLAudioElement
                el.paused ? el.play() : el.pause()
            }
        }}
    /> : <></>
}

function getRandomVoxo(): string {
    const archetypes = getArchetypes()
    const archetypeList = Object.entries(archetypes)
    const randomArchetypeIdx = Math.floor(Math.random() * archetypeList.length)
    const randomArchtype = archetypeList[randomArchetypeIdx][0]

    if (randomArchtype.startsWith('ASC'))
        if (Math.random() > 0.6) {
            const voxoName = `ASC-0${Math.floor(Math.random() * 8) + 1}`
            console.log(`Random Voxo: ${voxoName}`)
            return voxoName
        }
        else return getRandomVoxo()
    else if (randomArchtype === 'OXO-01')
        return getRandomVoxo()
    else {
        const randomFaction = ['A', 'B', 'C', 'D'][Math.floor(Math.random() * 4)]
        const rarities = Object.entries(archetypes[randomArchtype][randomFaction]).filter(r => r[1])
        const rarityRoulette = rarities.map((_, idx) => Math.random() * (idx === 0 ? 1 : idx === 1 ? 0.6 : 0.4))
        const randomRarityIdx = rarityRoulette.reduce(
            (acc, rn, idx) => rn > acc.rn ? ({ rn: Math.max(acc.rn, rn), idx }) : acc,
            { rn: 0, idx: 0 }
        ).idx
        const [randomRarity, poolSize] = rarities[randomRarityIdx]
        const randomNth = (Math.floor(Math.random() * poolSize) + 1).toString().padStart(4, '0')
        const voxoName = `${randomArchtype}_${randomFaction}_${randomRarity}${randomNth}`

        return voxoName
    }
}

const Viewer: React.FC = () => {
    const filename = useParams<{ filename: string }>().filename
    const voxoName = /^RANDOM-VOXO$/i.test(filename) ? getRandomVoxo() : filename && filename.toUpperCase()
    /**
     * Replace empty spaces in param names with '-'
     * Replace empty spaces in param values with '_'
     * @example
     * augSearch('extra pet=flash dog'); // returns 'extra_pet=flash-dog'
     */
    const augSearch = (window.location.search || '').split('&').map(param => {
        if (param.includes('=')) {
            const [paramName, paramValue] = param.split('=')
            return `${paramName.replace(/%20/g, '_')}=${paramValue.replace(/%20/g, '-')}`
        }
        return ''
    }).join('&')
    // const { urlReq, extras } = voxoName ?
    const { urlReq } = voxoName ?
        parseRequest(voxoName, new URLSearchParams(augSearch)) :
        {} as UrlParseResult
    const trackName = urlReq.voxoName !== ASSET.CUBE ?
        ([RARITY.UR, RARITY.UQ].some(r => r === urlReq.rarity.split(/\d+/)[0]) ?
            MP3.UR : MP3[urlReq.faction as keyof typeof MP3]) :
        MP3.NO_THEME
    const setUrlReq = useViewerStore(state => state.setUrlReq)
    const setName = useViewerStore(state => state.setName)
    // const setGear = useVoxoStore(state => state.setGear);
    const setAudio = useVoxoStore(state => state.setAudio)
    const toggleTurntable = useVoxoStore(state => state.toggleTurntable)
    const toggleEnv = useVoxoStore(state => state.toggleEnv)

    const addEnvToggle = useMenuStore(state => state.addEnvToggle)
    const addExtraEnvToggle = useMenuStore(state => state.addExtraEnvToggle)
    const addAnomalousEnvToggle = useMenuStore(state => state.addAnomalousEnvToggle)
    const addAddonToggle = useMenuStore(state => state.addAddonToggle)
    const addPetToggle = useMenuStore(state => state.addPetToggle)
    const addPetRobitToggle = useMenuStore(state => state.addPetRobitToggle)
    const addPetCollabToggle = useMenuStore(state => state.addPetCollabToggle)
    const addBonsaiToggle = useMenuStore(state => state.addBonsaiToggle)
    const addOmegaKeyToggle = useMenuStore(state => state.addOmegaKeyToggle)
    const addGildedToggle = useMenuStore(state => state.addGildedToggle)
    const setTokenId = useViewerStore(state => state.setTokenId)
    const setCamera = useViewerStore(state => state.setCamera)

    setTokenId(urlReq.token_id)
    setCamera({ loc: urlReq.camloc, lookat: urlReq.camlookat, fov: urlReq.fov })

    /**
     * fetch the header of the requested file with a success callback
     * @see http://localhost:3000/SM-01_C_R0002?extra_env=neon-edo&promo_env=promo-env&bespoke_env=bespoke-env&pet=cat&extra_pet_crossover=vogupet-elder&robo_pet=baby-ape&bonsai=bonsai-1-2
     */
    const requestHeader = (path: string, cb: any) => {
        fetch(path, { method: 'HEAD' })
            .then(res => { res.ok && cb() })
            .catch(console.warn)
    }
    const showMenu =
        !standaloneAssets.includes(voxoName) &&
        !bustAssets.includes(voxoName) &&
        !/^HC-/i.test(voxoName) &&
        urlReq.company.length === 0 &&
        urlReq.scene !== ASSET.PENTAFORM

    voxoName && setName(voxoName)
    urlReq && setUrlReq(urlReq)

    if (showMenu) {
        if (bucket) {
            urlReq.extra_420 && requestHeader(getPath(ASSET_TYPE.GLB, GLB_TYPE.ADDON, urlReq.extra_420), addAddonToggle)
            urlReq.extra_bonsai && requestHeader(getPath(ASSET_TYPE.GLB, GLB_TYPE.BONSAI, urlReq.extra_bonsai), addBonsaiToggle)
            urlReq.extra_pet && requestHeader(getPath(ASSET_TYPE.GLB, GLB_TYPE.PET, urlReq.extra_pet), addPetToggle)
            urlReq.extra_pet_collab && requestHeader(getPath(ASSET_TYPE.GLB, GLB_TYPE.PET_COLLAB, urlReq.extra_pet_collab), addPetCollabToggle)
            urlReq.extra_pet_robit && requestHeader(getPath(ASSET_TYPE.GLB, GLB_TYPE.PET_ROBIT, urlReq.extra_pet_robit), addPetRobitToggle)
            urlReq.extra_scene && requestHeader(getPath(ASSET_TYPE.GLB, GLB_TYPE.EXTRA_ENV, urlReq.extra_scene), addExtraEnvToggle)
            urlReq.extra_scene_anomalous && requestHeader(getPath(ASSET_TYPE.GLB, GLB_TYPE.ANOM_ENV, urlReq.extra_scene_anomalous), addAnomalousEnvToggle)
            urlReq.scene && requestHeader(getPath(ASSET_TYPE.GLB, GLB_TYPE.ENV, urlReq.scene), addEnvToggle)
            urlReq.omegakey && requestHeader(getPath(ASSET_TYPE.GLB, GLB_TYPE.SPECIAL, `OMEGA-STAGE-${urlReq.omegakey === 'TRUE' ? '2' : '0'}`), () => {
                addOmegaKeyToggle()
                addGildedToggle()
            })
        }
        else {
            urlReq.extra_420 && addAddonToggle()
            urlReq.extra_bonsai && addBonsaiToggle()
            urlReq.extra_pet && addPetToggle()
            urlReq.extra_pet_collab && addPetCollabToggle()
            urlReq.extra_pet_robit && addPetRobitToggle()
            urlReq.extra_scene && addExtraEnvToggle()
            urlReq.extra_scene_anomalous && addAnomalousEnvToggle()
            urlReq.omegakey && addOmegaKeyToggle()
            urlReq.gilded && addGildedToggle()
            urlReq.scene && addEnvToggle()
        }
    }

    useEffect(() => {
        if (trackName)
            setAudio(getPath(ASSET_TYPE.BGA, null, trackName))

        if (/^true$/i.test(urlReq.turntable.trim()))
            toggleTurntable()

        if (urlReq.scene === ASSET.PENTAFORM)
            toggleEnv()
        // setGear(extras.gear);
    }, [
        setAudio, toggleTurntable, toggleEnv,
        trackName, urlReq.turntable, urlReq.scene
        // setAudio, setGear, trackName, extras.gear
    ])

    return (<>
        <ThreeCanvas />
        <ControlPanel showMenu={showMenu} />
        <BGA />
        {!mode.production && <Logger />}
    </>)
}
const ControlWrapper = styled.div`
    position: absolute;
    right: 30px;
    top: 30px;
    display: grid;
    grid-row-gap: 20px;
    transform-origin: top right;
    transform: scale(1.1);
    z-index: 99;
`

export const useViewerStore = create<ViewerStore>(set => ({
    name: '',
    cameraData: {
        loc: defaultCameraLoc,
        lookat: defaultCameraLookat,
        fov: defaultFov
    },
    cameraControl: {} as OrbitControls,
    urlReq: {} as UrlParseResult["urlReq"],
    token_id: '',
    setTokenId: token_id => set(({ token_id })),
    setName: name => set({ name }),
    setCamera: ({ loc, lookat, fov }) => set(state => ({
        cameraData: {
            loc: loc || state.cameraData.loc,
            lookat: lookat || state.cameraData.lookat,
            fov: fov || state.cameraData.fov
        }
    })),
    setCameraControl: control => set({ cameraControl: control }),
    setUrlReq: reqData => set({ urlReq: reqData })
}))

export default Viewer