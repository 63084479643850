import create from 'zustand'
import React, { useState } from 'react'
import { Color, Mesh, MeshStandardMaterial, PerspectiveCamera, Vector2 } from 'three'
import { useThree, useFrame, createPortal } from '@react-three/fiber'
import { Plane, useFBO } from '@react-three/drei'

import Glb from './Glb'
import { useViewerStore } from './Viewer'

import { GearState, parseVoxoName } from '../utils'
import { GLB_TYPE, ASSET } from '../constants'

type VoxoStore = {
    audio: string
    audioActive: boolean
    bonsaiActive: boolean
    envActive: boolean
    extraEnvActive: boolean
    anomalousEnvActive: boolean
    gearActive: GearState
    addonActive: boolean
    petActive: boolean
    petRobitActive: boolean
    petCollabActive: boolean
    omegaKeyActive: boolean
    gildedActive: boolean
    cubeActive: boolean
    turntable: boolean
    setAudio: (track: string) => void
    setGear: (gear: GearState) => void
    toggleAudio: () => void
    toggleEnv: () => void
    toggleExtraEnv: () => void
    toggleAnomalousEnv: () => void
    toggleGear: (slot: string, option?: string) => void
    toggleAddon: () => void
    togglePet: () => void
    togglePetRobit: () => void
    togglePetCollab: () => void
    toggleBonsai: () => void
    toggleOmegaKey: () => void
    toggleGilded: () => void
    toggleCube: () => void
    toggleTurntable: () => void
}

/**
 * @
 * @see http://localhost:3000/AL-01_C_R2_2?company=EXS-01_D_R0002,SM-02_C_R0003,RL-02_B_R0005&interval=1
 * @see http://localhost:3000/RL-01_D_R0028?bg=angos&light=angos&company=SS-04_C_SR0009,RH-03_D_R0016,EXL-01_B_R0001,SM-03_A_UR0001,RS-01_C_SR0024,EXL-02_C_R0002,SS-03_D_R0052,RS-02_A_R0002&interval=0.5
 */
const Flipbook: React.FC = () => {
    const [frame, setFrame] = useState(0)
    const [voxoName, company, interval] = useViewerStore(state => [
        state.urlReq.voxoName,
        state.urlReq.company,
        state.urlReq.interval
    ])
    const heroes = [voxoName, ...company].map((hero, idx) => {
        const { type } = parseVoxoName(hero)

        return (
            <Glb
                key={`${hero}#${idx}`}
                filename={hero}
                subcategory={type}
                initPos={[0, type === GLB_TYPE.HERO ? 0.3 : 0, 0]}
                visible={frame === idx}
            />
        )
    })
    const bases = Array.from(new Set([voxoName, ...company].map(hero => parseVoxoName(hero).base)))
        .map(baseName => <Glb
            key={baseName}
            filename={baseName}
            subcategory={GLB_TYPE.BASE}
            visible={parseVoxoName(heroes[frame].props.filename).base === baseName}
        />)
    let elapsed = 0

    useFrame((_, delta) => {
        elapsed += delta

        if (elapsed > Number(interval)) {
            elapsed = 0

            if (frame < company.length) {
                setFrame(frame + 1)
            }
            else setFrame(0)
        }
    })

    return <>{heroes}{bases}</>
}
const Party: React.FC = () => {
    const [voxoName, party] = useViewerStore(state => [
        state.urlReq.voxoName,
        state.urlReq.party
    ])
    const heroes = [voxoName, ...party].map((hero, idx) => {
        const { type } = parseVoxoName(hero)

        return <Glb
            key={`${hero}#${idx}`}
            filename={hero}
            subcategory={type}
            initPos={[0, type === GLB_TYPE.HERO ? 0.3 : 0, 0]}
        />
    })
    const bases = Array.from(new Set([voxoName, ...party].map(hero => parseVoxoName(hero).base)))
        .map(baseName => <Glb
            key={baseName}
            filename={baseName}
            subcategory={GLB_TYPE.BASE}
        />)

    return <>{heroes}{bases}</>
}
const Hero: React.FC = () => {
    const [cubeActive, gildedActive, gearActive] = useVoxoStore(state => [
        state.cubeActive,
        state.gildedActive,
        state.gearActive
    ])
    const [voxoName, isHero, type] = useViewerStore(state => [
        state.urlReq.voxoName,
        state.urlReq.isHero,
        state.urlReq.type
    ])
    const hasBase = gildedActive || [ASSET.OMEGA, ASSET.MEDUSA].includes(voxoName as ASSET)
    const yOffset = isHero && !hasBase ? 0.3 : 0
    const cameraControl = useViewerStore(state => state.cameraControl)

    return voxoName !== ASSET.CUBE ? <Glb
        filename={voxoName}
        subcategory={gildedActive ? GLB_TYPE.GILDED : type}
        initPos={[0, yOffset, 0]}
        visible={!cubeActive}
        settings={{
            onclick: () => cameraControl.autoRotate = !cameraControl.autoRotate,
            gearActive
        }}
    /> : <></>
}
const BasePad: React.FC = () => {
    const [isHero, base, company] = useViewerStore(state => [
        state.urlReq.isHero,
        state.urlReq.base,
        state.urlReq.company
    ])
    const [cubeActive, gildedActive] = useVoxoStore(state => [state.cubeActive, state.gildedActive])

    return base && !company.length ?
        <Glb
            filename={base}
            subcategory={GLB_TYPE.BASE}
            initScl={[1.01, 1.01, 1.01]}
            visible={isHero && !cubeActive && !gildedActive}
        /> :
        <></>
}
const Environment: React.FC = () => {
    const env = useViewerStore(state => state.urlReq.scene)
    const [envActive, cubeActive] = useVoxoStore(state => [state.envActive, state.cubeActive])

    return envActive ?
        <Glb filename={env} subcategory={GLB_TYPE.ENV} visible={envActive && !cubeActive} /> :
        <></>
}
const ExtraEnvironment: React.FC = () => {
    const extraEnv = useViewerStore(state => state.urlReq.extra_scene)
    const [extraEnvActive, cubeActive] = useVoxoStore(state => [state.extraEnvActive, state.cubeActive])

    return extraEnvActive ?
        <Glb filename={extraEnv} subcategory={GLB_TYPE.EXTRA_ENV} visible={extraEnvActive && !cubeActive} /> :
        <></>
}
const AnomalousEnvironment: React.FC = () => {
    const anomalousEnv = useViewerStore(state => state.urlReq.extra_scene_anomalous)
    const [anomalousEnvActive, cubeActive] = useVoxoStore(state => [state.anomalousEnvActive, state.cubeActive])

    return anomalousEnvActive ?
        <Glb filename={anomalousEnv} subcategory={GLB_TYPE.ANOM_ENV} visible={anomalousEnvActive && !cubeActive} /> :
        <></>
}
const Addon: React.FC = () => {
    const [voxoName, env, extraEnv, anomalousEnv, addon] = useViewerStore(state => [
        state.urlReq.voxoName,
        state.urlReq.scene,
        state.urlReq.extra_scene,
        state.urlReq.extra_scene_anomalous,
        state.urlReq.extra_420
    ])
    const [envActive, extraEnvActive, anomalousEnvActive, addonActive, cubeActive] = useVoxoStore(state => [
        state.envActive,
        state.extraEnvActive,
        state.anomalousEnvActive,
        state.addonActive,
        state.cubeActive
    ])

    return addon ? <Glb
        filename={addon}
        env={(envActive && env) || (extraEnvActive && extraEnv) || (anomalousEnvActive && anomalousEnv) || undefined}
        archetype={voxoName.split('_')[0]}
        subcategory={GLB_TYPE.ADDON}
        initPos={[0, 0, -3]}
        offset={addon === ASSET.BONG ? 0 : 0.3}
        visible={addonActive && !cubeActive}
    /> : <></>
}
const Pet: React.FC = () => {
    const [env, extraEnv, anomalousEnv, pet] = useViewerStore(state => [
        state.urlReq.scene,
        state.urlReq.extra_scene,
        state.urlReq.extra_scene_anomalous,
        state.urlReq.extra_pet
    ])
    const [envActive, extraEnvActive, anomalousEnvActive, petActive, cubeActive] = useVoxoStore(state => [
        state.envActive,
        state.extraEnvActive,
        state.anomalousEnvActive,
        state.petActive,
        state.cubeActive
    ])

    return pet ? <Glb
        filename={pet}
        env={(envActive && env) || (extraEnvActive && extraEnv) || (anomalousEnvActive && anomalousEnv) || undefined}
        subcategory={GLB_TYPE.PET}
        initPos={[3, 0, 0]}
        visible={petActive && !cubeActive}
    /> : <></>
}
const PetRobit: React.FC = () => {
    const [env, extraEnv, anomalousEnv, petRobit] = useViewerStore(state => [
        state.urlReq.scene,
        state.urlReq.extra_scene,
        state.urlReq.extra_scene_anomalous,
        state.urlReq.extra_pet_robit,
    ])
    const [envActive, extraEnvActive, anomalousEnvActive, petRobitActive, cubeActive] = useVoxoStore(state => [
        state.envActive,
        state.extraEnvActive,
        state.anomalousEnvActive,
        state.petRobitActive,
        state.cubeActive
    ])

    return petRobit ? <Glb
        filename={petRobit}
        env={(envActive && env) || (extraEnvActive && extraEnv) || (anomalousEnvActive && anomalousEnv) || undefined}
        subcategory={GLB_TYPE.PET_ROBIT}
        initPos={[3, 0, 0]}
        visible={petRobitActive && !cubeActive}
    /> : <></>
}
const PetCollab: React.FC = () => {
    const [env, extraEnv, anomalousEnv, petCollab] = useViewerStore(state => [
        state.urlReq.scene,
        state.urlReq.extra_scene,
        state.urlReq.extra_scene_anomalous,
        state.urlReq.extra_pet_collab,
    ])
    const [envActive, extraEnvActive, anomalousEnvActive, petCollabActive, cubeActive] = useVoxoStore(state => [
        state.envActive,
        state.extraEnvActive,
        state.anomalousEnvActive,
        state.petCollabActive,
        state.cubeActive
    ])

    return petCollab ? <Glb
        filename={petCollab}
        env={(envActive && env) || (extraEnvActive && extraEnv) || (anomalousEnvActive && anomalousEnv) || undefined}
        subcategory={GLB_TYPE.PET_COLLAB}
        initPos={[3, 0, 0]}
        visible={petCollabActive && !cubeActive}
    /> : <></>
}
const ExtraBonsai: React.FC = () => {
    const bonsai = useViewerStore(state => state.urlReq.extra_bonsai)
    const [bonsaiActive, envActive, extraEnvActive, anomalousEnvActive, cubeActive] = useVoxoStore(state => [
        state.bonsaiActive,
        state.envActive,
        state.extraEnvActive,
        state.anomalousEnvActive,
        state.cubeActive
    ])

    return bonsai ? <Glb
        filename={bonsai}
        subcategory={GLB_TYPE.BONSAI}
        visible={bonsaiActive && !envActive && !extraEnvActive && !anomalousEnvActive && !cubeActive}
    /> : <></>
}
const OmegaKey: React.FC = () => {
    const omegaKey = useViewerStore(state => state.urlReq.omegakey).toLowerCase()
    const [omegaKeyActive, envActive, extraEnvActive, anomalousEnvActive, cubeActive] = useVoxoStore(state => [
        state.omegaKeyActive,
        state.envActive,
        state.extraEnvActive,
        state.anomalousEnvActive,
        state.cubeActive
    ])

    return ['true', 'false'].includes(omegaKey) ? <Glb
        filename={`OMEGA-STAGE-${omegaKey === 'true' ? '0' : '2'}`}
        subcategory={GLB_TYPE.SPECIAL}
        initPos={[0, 0, 12]}
        

        visible={omegaKeyActive && !envActive && !extraEnvActive && !anomalousEnvActive && !cubeActive}
    /> : <></>
}
const VoxoCube: React.FC = () => {
    const cubeActive = useVoxoStore(state => state.cubeActive)
    const voxoName = useViewerStore(state => state.urlReq.voxoName)

    return <Glb
        filename={ASSET.CUBE}
        subcategory={GLB_TYPE.PRESALE}
        visible={cubeActive || voxoName === ASSET.CUBE}
        settings={{
            material: { roughness: 0.3, metalness: 1 }
        }}
    />
}
const ShadowCatcher: React.FC = () => {
    const [envActive, extraEnvActive, anomalousEnvActive] = useVoxoStore(state => [
        state.envActive,
        state.extraEnvActive,
        state.anomalousEnvActive
    ])

    return <Plane
        receiveShadow
        position={(envActive || extraEnvActive || anomalousEnvActive) ? [0, -10, 0] : [0, 0, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        args={[100, 100]}
    >
        <shadowMaterial opacity={0.4} />
    </Plane>
}
const HeroCard: React.FC = () => {
    const target = useFBO()
    const { camera, scene } = useThree()
    const [voxoName, base, portal] = useViewerStore(state => [
        state.urlReq.voxoName,
        state.urlReq.base,
        state.urlReq.portal
    ])
    const heroName = voxoName.replace(/^HC-/i, '')

    console.log(portal)

    useFrame(state => {
        if (!portal) return

        const card = scene.getObjectByName(GLB_TYPE.CARD)
        const cel = scene.getObjectByName('RENDER') as Mesh
        const hero = scene.getObjectByName(GLB_TYPE.HERO)
        const base = scene.getObjectByName(GLB_TYPE.BASE)

        if (card && hero && cel && cel.material instanceof MeshStandardMaterial) {
            const bg = scene.background
            const cam = camera.clone() as PerspectiveCamera

            scene.background = null
            card.visible = false
            hero.visible = true
            base && (base.visible = true)

            // cam.position.x *= 2;
            // cam.position.y *= 2;
            // cam.position.z *= 2;
            // cam.position.divide(new Vector3(2, 2, 0.8));
            cam.position.multiplyScalar(2)
            cam.position.clampLength(10, 10)
            cam.lookAt(0, 3.5, 0)

            state.gl.setRenderTarget(target)
            state.gl.render(scene, cam)
            state.gl.setRenderTarget(null)

            if (target.texture.matrixAutoUpdate) {
                target.texture.rotation = Math.PI
                target.texture.center = new Vector2(0.5, 0.5)
                target.texture.repeat = new Vector2(-window.innerHeight / window.innerWidth, 1)
            }
            else {
                /**
                 * @see https://threejs.org/examples/webgl_materials_texture_rotation.html
                 */
                console.warn('matrixAutoUpdate is not supported')
            }

            cel.material.map = target.texture

            card.visible = true
            hero.visible = false
            base && (base.visible = false)
            scene.background = bg
        }
    })

    return <>
        {portal && createPortal(<>
            <Glb filename={heroName} initPos={[0, 0.3, 0]} subcategory={GLB_TYPE.HERO} />
            <Glb filename={base} subcategory={GLB_TYPE.BASE} />
        </>, scene)}
        <Glb filename={voxoName} subcategory={GLB_TYPE.CARD} settings={{
            material: {
                roughness: 1,
                metalness: 0,
                map: target.texture
            }
        }} />
    </>
}
const Voxo: React.FC = () => {
    const { scene } = useThree()
    const [name, bg, company, party] = useViewerStore(state => [
        state.name,
        state.urlReq.bg,
        state.urlReq.company,
        state.urlReq.party
    ])

    scene.background = new Color(bg).convertSRGBToLinear()

    if (name.startsWith('HC-')) {
        return <>
            <HeroCard />
            <ShadowCatcher />
        </>
    }
    else if (company.length) {
        return <Flipbook />
    }
    else if (party.length) {
        return <Party />
    }

    return <>
        {name && <group>
            <Hero />
            {name !== ASSET.MEDUSA && <BasePad />}
            <Environment />
            <ExtraEnvironment />
            <AnomalousEnvironment />
            <Addon />
            <Pet />
            <PetRobit />
            <PetCollab />
            <ExtraBonsai />
            <OmegaKey />
            <VoxoCube />
            <ShadowCatcher />
        </group>}
        {/* <fog attach="fog" args={[bg, 0, 40]} /> */}
    </>
}

export const useVoxoStore = create<VoxoStore>(set => ({
    audio: '',
    audioActive: false,
    envActive: false,
    extraEnvActive: false,
    anomalousEnvActive: false,

    gearActive: {},
    addonActive: false,
    petActive: false,
    petRobitActive: false,
    petCollabActive: false,
    bonsaiActive: false,
    omegaKeyActive: true,
    gildedActive: false,
    cubeActive: false,
    turntable: false,

    setAudio: track => set(({ audio: track })),
    setGear: gear => set(({ gearActive: gear })),

    toggleAudio: () => set(state => ({ audioActive: !state.audioActive })),
    toggleEnv: () => set(state => ({
        envActive: !state.envActive,
        extraEnvActive: false,
        anomalousEnvActive: false
    })),
    toggleExtraEnv: () => set(state => ({
        envActive: false,
        extraEnvActive: !state.extraEnvActive,
        anomalousEnvActive: false
    })),
    toggleAnomalousEnv: () => set(state => ({
        envActive: false,
        extraEnvActive: false,
        anomalousEnvActive: !state.anomalousEnvActive
    })),

    toggleGear: (slot, option) => set(state => {
        // const { gearActive: oldStates } = state;

        // Object.entries(oldStates).forEach(([_slot, options]) => {
        //     if (option === _slot)
        // });
        // return { gearActive: state.gearActive };
    }),
    toggleAddon: () => set(state => ({ addonActive: !state.addonActive })),
    togglePet: () => set(state => ({
        petActive: !state.petActive,
        petRobitActive: false
    })),
    togglePetRobit: () => set(state => ({
        petActive: false,
        petRobitActive: !state.petRobitActive
    })),
    togglePetCollab: () => set(state => ({ petCollabActive: !state.petCollabActive })),
    toggleBonsai: () => set(state => ({ bonsaiActive: !state.bonsaiActive })),
    toggleOmegaKey: () => set(state => ({ omegaKeyActive: !state.omegaKeyActive })),
    toggleGilded: () => set(state => ({ gildedActive: !state.gildedActive })),
    toggleCube: () => set(state => ({
        envActive: false,
        extraEnvActive: false,
        anomalousEnvActive: false,
        addonActive: false,
        petActive: false,
        petRobitActive: false,
        petCollabActive: false,
        bonsaiActive: false,
        cubeActive: !state.cubeActive
    })),
    toggleTurntable: () => set(state => ({ turntable: !state.turntable })),
}))

export default Voxo