import { WebGLRenderTarget, ShaderMaterial } from 'three';
import { Shaders } from '../Shader.js';

export default class compositePass {
    constructor(scene, camera, renderer, quad) {
        let shaders = new Shaders();

        this.compositeTarget = new WebGLRenderTarget(
            window.innerWidth,
            window.innerHeight
        );
        let compositeUniforms = {
            tDiffuse: {
                value: null
            },
            em: {
                value: null
            }
        };
        this.compositeMat = new ShaderMaterial({
            uniforms: compositeUniforms,
            fragmentShader: shaders.bloomMix(),
            vertexShader: shaders.vertexShader()
        });

        this.postScene = scene;
        this.renderer = renderer;
        this.postCamera = camera;
        this.quad = quad;
    }
    dispose() {
        this.compositeTarget.dispose();
    }
    resize(w, h) {
        this.compositeTarget.setSize(w, h);
    }
    update(dofTexture, bloomTexture) {
        this.renderer.setRenderTarget(this.compositeTarget);
        this.quad.material = this.compositeMat;
        this.quad.material.uniforms.tDiffuse.value = dofTexture;
        this.quad.material.uniforms.em.value = bloomTexture;
        this.renderer.render(this.postScene, this.postCamera);
        this.renderer.setRenderTarget(null);
    }
}